export const UpdatePriceInfoIcon = ({ width = '44', height = '45' }) => (
  <svg
    width={width}
    style={{ display: 'inline-block' }}
    height={height}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.875 21.75C16.8811 21.75 21.75 16.8811 21.75 10.875C21.75 4.8689 16.8811 0 10.875 0C4.8689 0 0 4.8689 0 10.875C0 16.8811 4.8689 21.75 10.875 21.75ZM12.0859 6.04101C12.0859 6.70836 11.5449 7.24935 10.8776 7.24935C10.2103 7.24935 9.66927 6.70836 9.66927 6.04101C9.66927 5.37367 10.2103 4.83268 10.8776 4.83268C11.5449 4.83268 12.0859 5.37367 12.0859 6.04101ZM9.66927 9.66601C9.66927 8.99867 10.2103 8.45768 10.8776 8.45768C11.5449 8.45768 12.0859 8.99867 12.0859 9.66601V15.7077C12.0859 16.375 11.5449 16.916 10.8776 16.916C10.2103 16.916 9.66927 16.375 9.66927 15.7077V9.66601Z'
      fill='#757575'
    />
  </svg>
);
