import Image from 'next/image';
import { cloudflareLoader } from 'imageLoader';
import { forwardRef, useContext, useState } from 'react';
import useRtl from 'hooks/useRtl';
import { DeviceDetector } from 'utils/dd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Navigation, Thumbs } from 'swiper';

interface PharmacyImageProps {
  src: string;
  priority?: boolean;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  width?: number;
  height?: number;
  minHeight?: number;
  quality?: number;
  zoom?: boolean;
  alt?: string;
  imageWidth?: number;
  imageHeight?: number;
  onClick?: () => void;
  localImage?: boolean;
  className?: string;
  style?: React.CSSProperties;
  layout?: 'fill' | 'responsive';
  ref?: any;
  images?: any[];
  setUrl?: (url: string) => void;
}

const PharmacyImage = forwardRef((props: PharmacyImageProps, ref) => {
  const { alt, priority, src, onClick, style, className, layout, zoom, images, setUrl } = props;
  const url = src;
  const isRtl = useRtl();
  const isMobile = useContext(DeviceDetector).isMobile;

  const [zoomStyle, setZoomStyle] = useState<React.CSSProperties>({ display: 'none' });
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoom || isMobile) {
      return;
    }
    const { top, left, width, height } = e.currentTarget.getBoundingClientRect();
    const x = e.pageX - left;
    const y = e.pageY - top;

    const windowWidth = window.innerWidth / 3;
    const zoomWidth = windowWidth;
    const zoomHeight = 600;

    const bgPosX = (x / width) * 100;
    const bgPosY = (y / height) * 100;

    setZoomStyle({
      backgroundImage: `url(${url})`,
      backgroundPosition: `${bgPosX}% ${bgPosY}%`,
      backgroundSize: `${width * 4}px ${height * 4}px`,
      width: `${zoomWidth}px`,
      height: `${zoomHeight}px`,
      border: '1px solid #000',
      display: 'block',
      position: 'fixed',
      left: isRtl ? '25%' : '40%',
      top: `20%`,
      zIndex: 1000,
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({ display: 'none' });
  };

  const handleImageClick = () => {
    if (isMobile && zoom) {
      setIsFullScreen(true);
    }
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', cursor: zoom ? 'zoom-in' : '' }}>
      <Image
        onClick={zoom ? handleImageClick : onClick}
        loader={cloudflareLoader}
        className={className}
        src={url}
        layout={layout}
        width={props.imageWidth ?? props.width}
        height={props.imageHeight ?? props.height}
        objectFit={props.objectFit}
        style={style}
        alt={alt}
        priority={priority}
        unoptimized={src?.startsWith('/local/') ? false : true}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
      <div style={zoomStyle}></div>
      {isFullScreen && zoom && isMobile && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <Image
            src={url}
            loader={cloudflareLoader}
            layout='fill'
            objectFit='contain'
            style={{ position: 'relative' }}
            alt={alt}
          />
          <button
            onClick={handleCloseFullScreen}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              backgroundColor: 'transparent',
              border: 'none',
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
            }}
          >
            &times;
          </button>
          {images && images.length > 0 && (
            <div style={{ position: 'absolute', bottom: '20px', width: '90%', zIndex: 1001 }}>
              <Swiper
                pagination={true}
                spaceBetween={10}
                centeredSlides={images?.length > 3 ? false : true}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className='pdpSwiper'
                wrapperClass='p-[10px]'
              >
                {images?.map((item: any, key) => (
                  <SwiperSlide
                    key={item.id}
                    className={item.original === url ? 'border-2 border-primary rounded-lg' : ''}
                  >
                    <Image
                      onClick={() => setUrl(item.original)}
                      loader={cloudflareLoader}
                      src={item.original || '/  local/image/placeholder_new.png'}
                      layout='responsive'
                      width={75}
                      height={50}
                      objectFit='cover'
                      alt={item.id}
                      priority={key == 0}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

PharmacyImage.displayName = 'Image';

export default PharmacyImage;
