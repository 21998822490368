import { ItemRequiredToSetEventResponse } from 'lib/helpers/gtm/events';

export const handleFbApiConversionAddToCart = async (productData: ItemRequiredToSetEventResponse) => {
  try {
    const response = await fetch('/api/fb-conversions-api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event_name: 'AddToCart',
        event_id: productData.sku,
        user_data: {
          email: 'user@example.com',
          phone: '123-456',
        },
        custom_data: {
          content_name: productData.name,
          content_ids: [productData.sku],
          content_type: 'product',
          quantity: productData?.product_pdp_quantity ?? 1,
          value: productData.price,
          currency: 'SAR',
        },
      }),
    });

    const result = await response.json();
    console.log('AddToCart Event sent:', result);
  } catch (error) {
    console.error('Error sending AddToCart event:', error);
  }
};

export const handleFbApiConversionPurchase = async (productData: any) => {
  try {
    const response = await fetch('/api/fb-conversions-api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event_name: 'Purchase',
        event_id: productData.transaction_id,
        user_data: {
          email: 'user@example.com',
          phone: '123-456',
        },
        custom_data: {
          content_ids: [productData?.transaction_id],
          value: productData.value,
          currency: 'SAR',
        },
      }),
    });

    const result = await response.json();
    console.log('AddToCart Event sent:', result);
  } catch (error) {
    console.error('Error sending AddToCart event:', error);
  }
};
