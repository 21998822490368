import React from 'react';
import Timer from 'components/Product/Timer';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import useRtl from 'hooks/useRtl';
function calculateStyles(offerTag: any, rtlEnd: any) {
  const { background, color, image, position, icon, text } = offerTag || {
    background: '',
    color: '',
    image: '',
    position: '',
    icon: '',
    text: '',
  };

  return {
    backgroundColor: (image?.[rtlEnd] ?? image) ? '' : background,
    color: color,
    maxWidth: '120px',
    height: '36px',
    maxHeight: '36px',
    width:
      position === 'below_image' ||
      position === 'above_image' ||
      ((icon?.[rtlEnd] && text?.[rtlEnd]?.length >= 12) ?? (icon && text?.length >= 12)) ||
      ((!icon?.[rtlEnd] && text?.[rtlEnd]?.length >= 21) ?? (!icon && text?.length >= 21))
        ? '120px'
        : 'fit',
    padding: (image?.[rtlEnd] ?? image) ? '0px' : '3px',
  };
}

const CustomPlpSearchListBadge = ({ item, t }) => {
  const customTextAlign = () => {
    switch (item?.text_alignment) {
      case 'right_aligned':
        return 'justify-end';
      case 'left_aligned':
        return 'justify-start';
      default:
        return 'justify-center';
    }
  };

  function calculateClassName() {
    switch (item?.position) {
      case 'below_image':
        return 'rounded-b-lg';
      case 'above_image':
        return 'rounded-t-lg';
      default:
        return 'rounded';
    }
  }
  const isRtl = useRtl();
  const rtlEnd = isRtl ? 'ar' : 'en';
  const styles = calculateStyles(item, rtlEnd);
  const positionClassName = calculateClassName();

  return (
    <div style={styles} className={`${positionClassName} ${customTextAlign()} px-1 flex  opacity-90 text-[12px]`}>
      <div className='flex items-center'>
        {item?.type === 'text' ? (
          <>
            <span>
              {typeof item?.icon === 'object'
                ? item?.icon?.[rtlEnd] && <img width='26' height='21' src={item?.icon?.[rtlEnd]} />
                : item?.icon && <img width='26' height='21' src={item?.icon} />}
            </span>

            <span className={item?.icon?.[rtlEnd] && `ml-[5px] rtl:pl-0 rtl:pr-1`}>
              {truncateText(
                typeof item?.text === 'object' ? item?.text?.[rtlEnd] : item?.text,
                item?.icon?.[rtlEnd] ? 11 : 15,
              )}
            </span>
          </>
        ) : (
          <>
            <img
              src={typeof item?.image === 'object' ? item?.image?.[rtlEnd] : item?.image}
              className='h-[56px] w-auto mt-3'
            />
          </>
        )}
      </div>
      {item?.countdown && (
        <div className='flex items-center justify-center'>
          <Timer isPlp t={t} expiry_start={item?.expiry_start} expiry_end={item?.expiry_end} />
        </div>
      )}
    </div>
  );
};

export default CustomPlpSearchListBadge;
