import { handshake, refresh } from 'models/auth';
import { NextPageContext } from 'next';
import { parseCookies, setCookie } from 'nookies';
import { initializeAlgoliaClient } from '../models/algolia-client';
import { checkIfTokenValid } from './check-expiration';

export const getAccessToken = async (ctx?: NextPageContext, errorMessage?: string) => {
  const currentRefreshToken = ctx ? parseCookies({ req: ctx.req }).refreshToken : parseCookies().refreshToken;

  const { accessToken, refreshToken, algoliaConfig } =
    !checkIfTokenValid(currentRefreshToken) ||
    errorMessage === 'Invalid token' ||
    errorMessage == "The consumer isn't authorized to access %resources."
      ? await handshake()
      : await refresh(currentRefreshToken);

  setCookie(ctx ?? null, 'accessToken', accessToken, {
    maxAge: 60 * 60 * 24,
    path: '/',
    httpOnly: false,
    secure: true,
    sameSite: 'none',
    domain: new URL(process.env.NEXT_PUBLIC_NEXTJS_DOMAIN ?? '').hostname,
    encode: (v) => v,
  });

  setCookie(ctx ?? null, 'refreshToken', refreshToken, {
    maxAge: 60 * 60 * 24 * 15,
    path: '/',
    httpOnly: false,
    secure: true,
    sameSite: 'none',
    domain: new URL(process.env.NEXT_PUBLIC_NEXTJS_DOMAIN ?? '').hostname,
    encode: (v) => v,
  });

  setCookie(ctx ?? null, 'algoliaConfig', JSON.stringify(algoliaConfig), {
    maxAge: 60 * 60 * 24,
    path: '/',
    httpOnly: false,
    secure: true,
    sameSite: 'none',
    domain: new URL(process.env.NEXT_PUBLIC_NEXTJS_DOMAIN ?? '').hostname,
    encode: (v) => v,
  });

  initializeAlgoliaClient(algoliaConfig, true);

  return { accessToken, algoliaConfig };
};
