import React, { createContext, useContext, useState, useEffect } from 'react';
import { Algolia, initializeAlgoliaClient } from '../models/algolia-client';
import { AlgoliaConfig } from '../types/auth/auth.type';

const AlgoliaContext = createContext<Algolia | null>(null);

export const AlgoliaProvider = ({ config, children }: { config: AlgoliaConfig; children: React.ReactNode }) => {
  const [algolia, setAlgolia] = useState<Algolia>(initializeAlgoliaClient(config));

  useEffect(() => {
    const newAlgolia = initializeAlgoliaClient(config, true, setAlgolia);
    setAlgolia(newAlgolia);
  }, []);

  return <AlgoliaContext.Provider value={algolia}>{children}</AlgoliaContext.Provider>;
};

export const useAlgolia = () => useContext(AlgoliaContext)!;
