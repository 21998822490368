import { useEffect, useState } from 'react';

/**
 * Create useDebounce
 * @param {any} value
 * @param {number} delay
 * @return {string}
 */
function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
