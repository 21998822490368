export const LogoutIcon = () => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{
      display: 'inline-block',
    }}
    className='rtl:rotate-180'
  >
    <path
      d='M17.3 8.29961C17.2074 8.39113 17.1338 8.50013 17.0836 8.62029C17.0334 8.74045 17.0076 8.86938 17.0076 8.99961C17.0076 9.12983 17.0334 9.25876 17.0836 9.37892C17.1338 9.49908 17.2074 9.60809 17.3 9.69961L19.2 11.5996H14C13.45 11.5996 13 12.0496 13 12.5996C13 13.1496 13.45 13.5996 14 13.5996H19.2L17.3 15.4996C17.2074 15.5911 17.1338 15.7001 17.0836 15.8203C17.0334 15.9405 17.0076 16.0694 17.0076 16.1996C17.0076 16.3298 17.0334 16.4588 17.0836 16.5789C17.1338 16.6991 17.2074 16.8081 17.3 16.8996C17.69 17.2896 18.31 17.2896 18.7 16.8996L22.29 13.3096C22.3827 13.2171 22.4562 13.1072 22.5064 12.9862C22.5566 12.8653 22.5824 12.7356 22.5824 12.6046C22.5824 12.4736 22.5566 12.344 22.5064 12.223C22.4562 12.102 22.3827 11.9921 22.29 11.8996L18.7 8.29961C18.6085 8.20697 18.4995 8.13342 18.3793 8.08322C18.2592 8.03302 18.1302 8.00717 18 8.00717C17.8698 8.00717 17.7408 8.03302 17.6207 8.08322C17.5005 8.13342 17.3915 8.20697 17.3 8.29961ZM9 19.5996H2C1.45 19.5996 1 20.0496 1 20.5996C1 21.1496 1.45 21.5996 2 21.5996H9C10.1 21.5996 11 20.6996 11 19.5996V5.59961C11 4.49961 10.1 3.59961 9 3.59961H2C1.45 3.59961 1 4.04961 1 4.59961C1 5.14961 1.45 5.59961 2 5.59961H9V19.5996Z'
      fill='#E81103'
    />
  </svg>
);
