export const PlusCartItemIcon = ({ color = '#F7F7F8', signColor = '#2E8F66' }) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 -0.000488281H28C30.2091 -0.000488281 32 1.79037 32 3.99951V27.9995C32 30.2087 30.2091 31.9995 28 31.9995H0V-0.000488281Z'
      fill={color}
    />
    <path
      d='M25 17.2852H17.2857V24.9995H14.7143V17.2852H7V14.7138H14.7143V6.99951H17.2857V14.7138H25V17.2852Z'
      fill={signColor}
    />
  </svg>
);
