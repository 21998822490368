import { SVG } from 'lib/interface';

export const PrescriptionIcon = ({ color = '#595959', width = '24', height = '25' }: SVG) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 25'
    fill='none'
    style={{ display: 'inline-block' }}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.768 16.3496L18.8172 13.3004C19.0613 13.0562 19.0613 12.6605 18.8172 12.4164L17.9332 11.5324C17.6891 11.2883 17.2934 11.2883 17.0492 11.5324L14 14.5816L10.7203 11.302C12.5668 11.0684 14 9.50937 14 7.59961C14 5.52852 12.3211 3.84961 10.25 3.84961H4.625C4.27969 3.84961 4 4.1293 4 4.47461V14.4746C4 14.8199 4.27969 15.0996 4.625 15.0996H5.875C6.22031 15.0996 6.5 14.8199 6.5 14.4746V11.3496H7.23242L12.2324 16.3496L9.1832 19.3988C8.93906 19.643 8.93906 20.0387 9.1832 20.2828L10.0672 21.1668C10.3113 21.4109 10.707 21.4109 10.9512 21.1668L14 18.1172L17.0492 21.1664C17.2934 21.4105 17.6891 21.4105 17.9332 21.1664L18.8172 20.2824C19.0613 20.0383 19.0613 19.6426 18.8172 19.3984L15.768 16.3496ZM6.5 6.34961H10.25C10.9391 6.34961 11.5 6.91055 11.5 7.59961C11.5 8.28867 10.9391 8.84961 10.25 8.84961H6.5V6.34961Z'
      fill={color}
    />
  </svg>
);
