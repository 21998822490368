import { useQuery } from '@tanstack/react-query';
import { get_lakum_history } from 'models/lakum';
import useStoreView from 'hooks/useStoreView';
import useLakumInfo from 'hooks/useLakumInfo';

export default function useGetLakumHistory(options: any = {}) {
  const { isLakumActive } = useLakumInfo();
  const [storeView] = useStoreView();
  return useQuery(
    ['lakum-history', storeView],
    async () => {
      return get_lakum_history();
    },
    {
      enabled: isLakumActive,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
}
