import axios from 'axios';
import { errorToast } from 'lib/taost';
import { i18n } from 'next-i18next';
import { turnErrorMessagesIntoKeyFormat } from './turn-error-msg-to-key-format';
import { errorSchema } from './error-message-validation-schema';
import { sleep } from './sleep';
import isArray from 'lodash/isArray';

export const apolloErrorToast = async (
  data: { message: string; Api: string; parameters?: Record<string, string> | string[] },
  timeToDelayApiCall = 2000,
) => {
  const { message, parameters, Api } = data ?? {};

  if (
    !validateError({
      message,
      parameters,
    })
  ) {
    return;
  }

  const parametersToShow = isArray(parameters) ? convertArrParametersToObject(message, parameters) : parameters;
  const messageKey = turnErrorMessagesIntoKeyFormat(message);

  errorToast(i18n?.t(messageKey, { ...parametersToShow }) || 'something went wrong');

  // if messages doesn't exist in localize
  if (messageKey === i18n?.t(messageKey)) {
    try {
      timeToDelayApiCall && (await sleep(timeToDelayApiCall));
      await axios.post('/api/record-error-message-keys', { message, Api, path: window.location.href });
    } catch (error) {
      console.log(error);
    }
  }
};

const validateError = (error: unknown) => {
  try {
    errorSchema.validateSync(error);
    return true;
  } catch (error) {
    errorToast(i18n?.t('something_went_wrong') || 'something went wrong');
    console.log(error);
    return false;
  }
};

const convertArrParametersToObject = (message: string, parameters: string[]) => {
  if (!parameters) {
    return;
  }
  // regex to get parameters from sting
  const regex = /%(\w+)/g;

  let match: RegExpExecArray | null;
  const variablesInMessage: string[] = [];
  while ((match = regex.exec(message)) !== null) {
    variablesInMessage.push(match[1]);
  }

  return variablesInMessage.reduce((parametersAsObject, variable, index) => {
    return {
      ...parametersAsObject,
      [variable]: parameters[index],
    };
  }, {});
};
