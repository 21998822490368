import { FilterData } from 'components/Product/Vertical';
import { filterCOmponentLeftSideList } from 'lib/constants';

export function convertBackendObjToSchema(backendObj, isRTL) {
  const result = {};

  if (!filterCOmponentLeftSideList) {
    console.error('filterCOmponentLeftSideList is undefined or null');
    return result;
  }

  const keysToUse = Object.keys(filterCOmponentLeftSideList);

  keysToUse.forEach((key) => {
    if (key.includes('hierarchicalCategories')) {
      return;
    }

    const options: any[] = [];
    let position = 0;

    const backendKey = isRTL ? filterCOmponentLeftSideList[key]?.ar : filterCOmponentLeftSideList[key]?.en;

    if (!backendKey || !backendObj[backendKey]) {
      return;
    }

    Object.entries(backendObj[backendKey]).forEach(([label, amount]) => {
      options.push({ value: position++, label: !isNaN(parseInt(label)) ? parseInt(label) : label, amount });
    });

    const label = isRTL ? filterCOmponentLeftSideList[key]?.labelAr : filterCOmponentLeftSideList[key]?.labelEn;

    const cleanKey = key.charAt(0).toLowerCase() + key.slice(1);

    result[cleanKey] = { position: 0, label, options };
  });

  return result;
}

export function generateAlgoliaQuery(data: any, isRtl: boolean) {
  delete data.keyword;
  const groupedData = groupDataByLabel(data, isRtl);
  return transformGroupedData(groupedData);
}

function groupDataByLabel(data: any, isRtl: boolean): { [key: string]: any[] } {
  const groupedData: { [key: string]: any[] } = {};
  for (const key in data) {
    const label_en = filterCOmponentLeftSideList[key]?.en;
    const label_ar = filterCOmponentLeftSideList[key]?.ar;
    for (const value of data[key]) {
      if (value) {
        if (!groupedData[label_en]) {
          groupedData[label_en] = [];
        }
        if (!groupedData[label_ar]) {
          groupedData[label_ar] = [];
        }
        groupedData[label_en].push(`${label_en}:${value}`, `${label_ar}:${value}`);
      }
    }
  }
  return groupedData;
}

function transformGroupedData(groupedData: { [key: string]: any[] }): any[] {
  const result: any[] = [];

  for (const label in groupedData) {
    result.push(groupedData[label]);
  }
  return result;
}

export function generateStringArray(
  arrayOfObj: [{ key: string; value: string }],
  activeFilter: string,
  isRtl: boolean,
  key: string,
) {
  return arrayOfObj.map((item) => {
    return `${filterCOmponentLeftSideList[activeFilter]?.[isRtl ? 'ar' : 'en']}:${item[key]}`;
  });
}

export function generateConvertedFiltersArray(filtersObj: FilterData, isRtl: boolean) {
  const groupedData: { [key: string]: any[] } = {};
  for (const key in filtersObj) {
    const label = filterCOmponentLeftSideList[key]?.[isRtl ? 'ar' : 'en'];
    for (const value of filtersObj[key]) {
      if (value && groupedData[label]) {
        groupedData[key].push(`${label}:${value}`);
      } else {
        groupedData[key] = [`${label}:${value}`];
      }
    }
  }
  return groupedData;
}

export function extractEntities(arr) {
  const resultObj = {};

  function traverse(obj) {
    if (obj.children_data.length === 0) {
      resultObj[obj.entity_id] = { entity_id: obj.entity_id, name: obj.name, image: obj.image };
    } else {
      resultObj[obj.entity_id] = { entity_id: obj.entity_id, name: obj.name, image: obj.image };
      obj.children_data.forEach((child) => traverse(child));
    }
  }

  arr?.forEach((obj) => traverse(obj));

  return resultObj;
}

export function containsEnglishLetters(word: string) {
  const regex = /[a-zA-Z]/;
  return regex.test(word);
}

export function filterObjByLanguage(obj, isRtl) {
  const isEnglishLetter = (str) => /[a-zA-Z]/.test(str);
  const isArabicLetter = (str) => /[\u0600-\u06FF]/.test(str);
  let flag = false;

  const filterFunction = isRtl ? isEnglishLetter : isArabicLetter;

  for (const key in obj) {
    if (key !== 'composition' && key !== 'category') {
      const values = obj[key];
      flag = values?.some(filterFunction);
      if (flag) {
        delete obj[key];
      }
    }
  }

  return { obj, flag };
}

export function searchNameInArray(arr, str) {
  function search(arr) {
    for (const item of arr) {
      if (item.name === str) {
        return true;
      }
      if (item.children_data && item.children_data.length > 0) {
        if (search(item.children_data)) {
          return true;
        }
      }
    }
    return false;
  }

  return search(arr);
}

export function createParentCategories(categoryArr, algoliaArr) {
  const parents: any = [];
  algoliaArr?.forEach((element) => {
    categoryArr?.forEach((item) => {
      if (searchNameInArray([item], element.facetValue)) {
        if (!parents.some((itm) => itm.name === item.name)) {
          parents.push(item);
        }
      }
    });
  });
  return parents;
}

export function createCategoryContainerObj(arr) {
  const resultObj = {};

  function createObj(obj) {
    resultObj[obj.name] = { entity_id: obj.entity_id, name: obj.name, image: obj.image };
  }
  arr?.forEach((obj) => createObj(obj));

  return resultObj;
}

export function hasCategoryFilters(url) {
  return url.includes('filter=');
}

export function removeFiltersFromURL(url) {
  const [base, query] = url.split('?');

  if (!query) {
    return url;
  }

  const params = query.split('&');

  const filteredParams = params.filter((param) => {
    if (param.startsWith('filter=')) {
      return param.startsWith('filter=category=');
    }
    return false;
  });

  return `${base}?${filteredParams.join('&')}`;
}
