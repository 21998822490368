import { del, get, post, put } from 'models/base';
import type { GenericAbortSignal } from 'axios';
import {
  Customer,
  CustomerUpdateRequest,
  HmgLoginRequest,
  LoginOtp,
  LoginRequest,
  Order,
  OrderCancelationRequest,
  OrderInvoice,
  OrderRefundRequest,
  OrderTracking,
  Orders,
  VerifyOtp,
} from '../types/customer/customer.type';
import { Cart } from '../types/cart/cart.type';

export const login = async (zip_code: string, number: string, token: string): Promise<LoginOtp> => {
  const { data } = await post('/customer/login', {
    phone: {
      zip_code,
      number,
    },
    token,
  } as LoginRequest);
  return data;
};

export const hmg_login = async (
  zip_code: string | undefined,
  number: string | undefined,
  national_id: string | undefined,
  type: string,
  token: string,
): Promise<LoginOtp> => {
  const { data } = await post('/customer/hmg_login', {
    phone: {
      zip_code,
      number,
    },
    token,
    [type]: national_id,
  } as HmgLoginRequest);
  return data;
};

export const verify_otp = async (otp: VerifyOtp, req?: any): Promise<Customer> => {
  const { data } = await post('/customer/verify_otp', otp, req);
  return data;
};

export const update_customer = async (customerDetails: CustomerUpdateRequest): Promise<Customer> => {
  const { data } = await put('/customer', customerDetails);
  return data;
};

export const get_customer_order = async (order_id: string, req?: any): Promise<Order> => {
  const { data } = await get(`/customer/orders/${order_id}?useIncrementId=true`, req);
  return data;
};

export const get_order_tracking = async (order_id: any, req?: any): Promise<OrderTracking> => {
  const { data } = await get(`/customer/orders/${order_id}/tracking-history`, req);
  return data;
};

export const get_customer_orders = async (
  page: number,
  size: number = 10,
  status: string,
  id?: string,
  req?: any,
  isRefunds?: any,
  signal?: GenericAbortSignal,
): Promise<Orders> => {
  let url = `/customer/orders?current_page=${page}&page_size=${size}`;
  if (isRefunds && !status) url += `&status=`;
  if (status) url += `&status=${status}`;
  if (id) url += `&increment_id=${id}`;
  const { data } = await get(url, req, signal);
  return data;
};

export const re_order = async (id: number, req?: any): Promise<Cart> => {
  const { data } = await post(`/customer/orders/${id}/reorder`, {}, req);
  return data;
};

export const cancel_order = async (id: number, reason?: string, req?: any): Promise<void> => {
  const { data } = await post(`/customer/orders/${id}/cancel`, { reason } as OrderCancelationRequest, req);
  return data;
};

export const get_invoice_by_order_id = async (id: any, req?: any) => {
  const { data } = await get(`/customer/orders/${id}/invoice`, req);
  return data;
};

export const get_avaiable_invoices_by_order_id = async (id: any, req?: any): Promise<OrderInvoice[]> => {
  const { data } = await get(`/customer/orders/${id}/available-invoices`, req);
  return data;
};

export const return_order = async (id: number, refundData: OrderRefundRequest, req?: any): Promise<void> => {
  const { data } = await post(
    `${
      process.env.NEXT_PUBLIC_API_PRIVATE_URL || 'https://alpha-core.alhabibpharmacydev.com'
    }/customer/orders/${id}/return`,
    refundData,
    req,
    true,
  );
  return data;
};

export const get_customer_data = async (req?: any): Promise<Customer> => {
  const { data } = await get('/customer', req);
  return data;
};

export const logout = async (req?: any): Promise<void> => {
  const { data } = await post('/customer/logout', {}, req);
  return data;
};

export const subscribe = async (req?: any): Promise<Customer> => {
  const { data } = await post('/customer/subscribe', {}, req);
  return data;
};

export const unsubscribe = async (req?: any): Promise<Customer> => {
  const { data } = await post('/customer/unsubscribe', {}, req);
  return data;
};

export const delete_customer = async (req?: any): Promise<void> => {
  const { data } = await del('/customer', {}, req);
  return data;
};
