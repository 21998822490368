import { SVG } from 'lib/interface';

export const UserIcon = ({ className, color = '#757575' }: SVG) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ display: 'inline-block' }}
    className={className}
  >
    <path
      d='M11.9987 11.3333C12.9217 11.3333 13.8239 11.0596 14.5914 10.5469C15.3588 10.0341 15.9569 9.30525 16.3101 8.45253C16.6633 7.5998 16.7558 6.66149 16.5757 5.75625C16.3956 4.851 15.9512 4.01948 15.2985 3.36684C14.6459 2.71419 13.8144 2.26974 12.9091 2.08967C12.0039 1.90961 11.0656 2.00202 10.2128 2.35523C9.36012 2.70844 8.63129 3.30658 8.11851 4.07401C7.60573 4.84144 7.33203 5.74369 7.33203 6.66667C7.33203 7.90435 7.8237 9.09133 8.69887 9.9665C9.57404 10.8417 10.761 11.3333 11.9987 11.3333ZM11.9987 3.33334C12.658 3.33334 13.3024 3.52883 13.8506 3.8951C14.3988 4.26137 14.826 4.78197 15.0783 5.39106C15.3306 6.00014 15.3966 6.67037 15.268 7.31697C15.1394 7.96357 14.8219 8.55752 14.3557 9.02369C13.8895 9.48987 13.2956 9.80734 12.649 9.93595C12.0024 10.0646 11.3322 9.99856 10.7231 9.74627C10.114 9.49398 9.5934 9.06673 9.22713 8.51857C8.86086 7.97041 8.66536 7.32594 8.66536 6.66667C8.66536 5.78261 9.01655 4.93477 9.64168 4.30965C10.2668 3.68452 11.1146 3.33334 11.9987 3.33334Z'
      fill={color}
    />
    <path
      d='M20.312 16.2465C19.2429 15.1165 17.9545 14.2165 16.5257 13.6013C15.0969 12.9862 13.5576 12.6689 12.002 12.6689C10.4464 12.6689 8.90716 12.9862 7.47834 13.6013C6.04952 14.2165 4.76116 15.1165 3.69203 16.2465C3.46024 16.4941 3.33151 16.8207 3.33203 17.1599V20.6665C3.33203 21.0202 3.47251 21.3593 3.72256 21.6093C3.97261 21.8594 4.31174 21.9999 4.66537 21.9999H19.332C19.6857 21.9999 20.0248 21.8594 20.2748 21.6093C20.5249 21.3593 20.6654 21.0202 20.6654 20.6665V17.1599C20.6677 16.8216 20.5414 16.4951 20.312 16.2465ZM19.332 20.6665H4.66537V17.1532C5.61018 16.1583 6.74756 15.3661 8.00827 14.8247C9.26898 14.2834 10.6267 14.0042 11.9987 14.0042C13.3707 14.0042 14.7284 14.2834 15.9891 14.8247C17.2498 15.3661 18.3872 16.1583 19.332 17.1532V20.6665Z'
      fill={color}
    />
  </svg>
);
