import { initializeAdjust } from 'models/adjust-client';

declare global {
  interface Window {
    Adjust: any;
  }
}

let adjustInstance = initializeAdjust();

function getAdjustInstance() {
  if (!adjustInstance && typeof window !== 'undefined') {
    const Adjust = require('@adjustcom/adjust-web-sdk');
    Adjust.initSdk({
      appToken: '7tfdwbuv9z40',
      environment: 'production',
      logLevel: 'debug',
    });
    adjustInstance = Adjust;
  }
  return adjustInstance;
}

function AdjustEvent(eventToken: string, revenue?: number, currency?: string) {
  const Adjust = getAdjustInstance();

  Adjust.trackEvent({
    eventToken: eventToken,
    revenue: revenue,
    currency: currency,
  });
}

export default AdjustEvent;
