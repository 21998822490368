import React, { useEffect, useState } from 'react';
import useSessionStorage from 'hooks/useSessionStorage';

const Timer = ({ expiry_start, expiry_end, t, isPlp = false }) => {
  const [estimatedTime, setEstimatedTime] = useState('');
  const [show, setShow] = useSessionStorage('pdpCountdown', true);

  useEffect(() => {
    const calculateEstimatedTime = () => {
      const currentDateTime = new Date();
      const startDateTime = new Date(expiry_start);
      const endDateTime = new Date(expiry_end);

      if (startDateTime > currentDateTime) {
        const timeDifference = startDateTime.getTime() - currentDateTime.getTime();
        setEstimatedTime(convertTimeToString(timeDifference));
        setShow(true);
      } else if (endDateTime > currentDateTime) {
        const timeDifference = endDateTime.getTime() - currentDateTime.getTime();
        setEstimatedTime(convertTimeToString(timeDifference));
        setShow(true);
      } else {
        setEstimatedTime(convertTimeToString(0));
        setShow(false);
      }
    };

    const interval = setInterval(calculateEstimatedTime, 1000);
    return () => clearInterval(interval);
  }, [expiry_start, expiry_end]);

  const convertTimeToString = (timeDifference) => {
    const seconds = Math.floor(timeDifference / 1000);
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const getHtml = (label: string) => {
      if (isPlp) return `<span style='font-size: 9px;font-weight: 500'>${label}</span>`;
      return `<span style='font-size: 12px;font-weight: 500'>${label}</span>`;
    };
    return `${String(days).padStart(1, '')} ${getHtml('d')} ${String(hours).padStart(2, '0')} ${getHtml('h')} ${String(
      minutes,
    ).padStart(2, '0')} ${getHtml('m')} ${String(remainingSeconds).padStart(2, '0')} ${getHtml('s')}`;
  };
  if (!show) return <></>;
  return (
    <div dir='ltr' className='flex justify-center'>
      <p>
        <span className={isPlp ? `text-[9px] font-[500]` : `text-[12px] font-[500]`}>{t('only')}</span>{' '}
        <span
          style={{ fontSize: isPlp ? '9px' : '12px', fontWeight: '700' }}
          dangerouslySetInnerHTML={{ __html: estimatedTime }}
        ></span>{' '}
        <span className={isPlp ? `text-[9px] font-[500]` : `text-[12px] font-[500]`}>{t('left')}</span>
      </p>
    </div>
  );
};

export default Timer;
