import { SVG } from 'lib/interface';

export const RefundIcon = ({ color = '#595959', className }: SVG) => (
  <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M18.8915 4.27756V14.6341C18.8915 15.208 18.4219 15.6776 17.848 15.6776H2.4828C1.90889 15.6776 1.43932 15.208 1.43932 14.6341C1.43932 14.0602 1.90889 13.5906 2.4828 13.5906H16.8045V5.24278H3.6828L5.11758 6.70365C5.53497 7.12104 5.53497 7.7993 5.11758 8.21669C4.90889 8.42539 4.64802 8.52974 4.38715 8.52974C4.12628 8.52974 3.86541 8.42539 3.65671 8.21669L0.42193 5.008C0.213234 4.7993 0.108887 4.53843 0.108887 4.27756C0.108887 3.99061 0.213234 3.72974 0.42193 3.54713L3.65671 0.338434C4.0741 -0.0789572 4.72628 -0.0789572 5.14367 0.338434C5.56106 0.755825 5.53497 1.38191 5.14367 1.7993L3.6828 3.15583H17.848C18.4219 3.15583 18.8915 3.70365 18.8915 4.27756Z'
      fill={color}
    />
  </svg>
);
