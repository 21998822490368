import { SVG } from 'lib/interface';

export const OrderIcon = ({ color = '#595959' }: SVG) => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    style={{ display: 'inline-block' }}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.5029 5.29085L13.9086 0.213913C13.7669 0.138928 13.6078 0.0996094 13.4461 0.0996094C13.2844 0.0996094 13.1253 0.138928 12.9837 0.213913L3.38927 5.29085C3.23884 5.37053 3.1134 5.4876 3.02603 5.62983C2.93867 5.77207 2.89258 5.93425 2.89258 6.09946C2.89258 6.26468 2.93867 6.42686 3.02603 6.5691C3.1134 6.71133 3.23884 6.8284 3.38927 6.90808L12.4867 11.7219V21.6164L9.57381 20.0749L8.64891 21.6912L12.9837 23.985C13.1253 24.0602 13.2844 24.0996 13.4461 24.0996C13.6078 24.0996 13.7669 24.0602 13.9086 23.985L23.5029 18.9081C23.6534 18.8285 23.7789 18.7114 23.8664 18.5692C23.9538 18.4269 23.9999 18.2647 23.9999 18.0995V6.09946C23.9999 5.93422 23.9538 5.77202 23.8664 5.62978C23.7789 5.48755 23.6534 5.37049 23.5029 5.29085ZM13.4461 2.07669L21.0497 6.09946L13.4461 10.1222L5.84255 6.09946L13.4461 2.07669ZM22.0811 17.554L14.4055 21.6155V11.721L22.0811 7.65947V17.554Z'
      fill={color}
    />
    <path d='M6 12.0996H0V10.0996H6V12.0996Z' fill={color} />
    <path d='M7.03583 20.0996H0V18.0996H7.03583V20.0996Z' fill={color} />
    <path d='M10 16.0996H2V14.0996H10V16.0996Z' fill={color} />
  </svg>
);
