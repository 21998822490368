import { Button } from 'components/Button';
import { CloseIcon } from 'components/SvgIcons/CloseIcon';
import { UpdatePriceInfoIcon } from 'components/SvgIcons/UpdatePriceIcon';
import useCart from 'hooks/useCart';
import useSessionStorage from 'hooks/useSessionStorage';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const CustomModal = () => {
  const { showModal, setShowModal } = useCart();
  const { t } = useTranslation('common');
  const { push, pathname } = useRouter();
  const [modalRoute, setModalRoute] = useSessionStorage('modalRoute', '');

  const handleCLoseModal = () => {
    if (modalRoute) {
      push(modalRoute);
    }
    setShowModal(false);
    setModalRoute('');
  };
  return (
    <Modal
      style={{
        overlay: {
          zIndex: 299,
          display: 'grid',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          width: '80%',
          maxWidth: '500px',
          margin: 'auto',
          textAlign: 'center',
        },
      }}
      className='orderModal flex flex-col gap-[15px]'
      isOpen={showModal}
    >
      <div className='relative'>
        <button className='right-0 absolute' onClick={handleCLoseModal}>
          <CloseIcon width={20} />
        </button>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <div className=''>
          <UpdatePriceInfoIcon />
        </div>
        <h1 style={{ fontSize: '23px', color: 'black' }} className='ap-headline'>
          {t('error_price_update')}
        </h1>
        <p>{t('error_price_update_message')}</p>
      </div>
      <Button onClick={handleCLoseModal} title='OK' className='mt-4' />
    </Modal>
  );
};

export default CustomModal;
