/* eslint-disable max-lines */
import {
  add_cart_item,
  apply_coupon_code,
  apply_lukam_points,
  apply_store_credit,
  delete_cart_item,
  delete_store_credit,
  get_cart,
  place_order,
  remove_coupon_code,
  remove_lukam_points,
  remove_store_credit,
  update_cart_item,
  available_shipping_methods,
  pick_location_info,
  update_payment_methods,
  check_cart_stock,
  add_a_note,
  CartApiUrls,
} from 'models/cart';
import { errorToast, successToast } from 'lib/taost';
import useLocalStorage from 'hooks/useLocalStorage';
import { useRouter } from 'next/router';
import { useLoading } from 'hooks/useLoading';
import Payfort from '../utils/payment/payfort';
import useSessionStorage from 'hooks/useSessionStorage';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import useCookieStorage from 'hooks/useCookieStorage';
import { useEffect, useState } from 'react';
import { apolloErrorToast } from 'utils/apollo-error-toast';
import { isEmpty } from 'lodash';
import { Cart, CartItem, Media, ActionCart, ShippingMethod } from '../types/cart/cart.type';


let lakumStart = true,
  couponStart = true,
  storeCreditStart = true;

const useCart = () => {
  const { t } = useTranslation('common');
  const [cartData, setCartData] = useLocalStorage('cart', {} as Cart);
  const [, setOrderResult] = useLocalStorage('order_result', {});
  let [paymentMethod] = useLocalStorage('paymentMethod', 'aps_fort_cc');
  const [ccError] = useSessionStorage('cc_error', false);
  const [showModal, setShowModal] = useSessionStorage('modalStatus', false);
  const [modalRoute, setModalRoute] = useSessionStorage('modalRoute', '');
  const { push, locale } = useRouter();
  const [loading, setLoading] = useLoading();
  const [activeCard] = useSessionStorage('activeCard', {
    entity_id: Date.now(),
    name: '',
    card_number: '',
    expiry_date: '',
    card_security_code: '',
    card_holder_name: '',
    remember_me: true,
    isVault: false,
    public_hash: '',
    originalValue: '',
  });

  const queryClient = useQueryClient();
  const [, setIsLoggin] = useCookieStorage('isCustomerLoggedIn', false, {});
  const [, setDeliveryMethods] = useSessionStorage('deliveryMethods', [] as ShippingMethod[] | string);
  const [, setShippingMethodLoading] = useSessionStorage('deliveryMethodsLoading', true);
  const [, setPaymentMethodsLoading] = useSessionStorage('paymentMethodsLoading', true);
  const [cartItems, setCartItems] =
    useState<(Omit<CartItem, 'price' | 'media'> & { price: { price: number }; media?: Media })[]>();

  useEffect(() => {
    setCartItems(
      cartData?.items?.map((item) => {
        const updatedItem: any = cartData.totals.items.find(e => item.item_id === e.item_id)
        return {
        ...item,
        price: { price: updatedItem?.base_price_incl_tax},
        media: item?.product && { ...item.product.media },
      }}),
    );
  }, [cartData]);

  useEffect(() => {
    if (cartData?.items_qty === 0) {
      const lakum = cartData?.totals?.total_segments?.find((segment: any) => segment.code === 'lakum_loyalty_discount');
      const coupon = cartData?.totals?.total_segments?.find((segment: any) => segment.code === 'discount');
      const customerbalance =
        cartData?.totals?.total_segments?.find((segment: any) => segment.code === 'customerbalance')?.value !== 0;
      (async () => {
        if (lakum && lakumStart) {
          lakumStart = false;
          await remove_lukam_points(lakum?.value).then(() => {
            getCartData();
            lakumStart = true;
          });
        }
        if (coupon && couponStart) {
          couponStart = false;
          await remove_coupon_code(coupon?.value).then(() => {
            getCartData();
            couponStart = true;
          });
        }
        if (customerbalance && storeCreditStart) {
          storeCreditStart = false;
          await remove_store_credit().then(() => {
            getCartData();
            storeCreditStart = true;
          });
        }
      })();
    }
  }, [cartData?.items_qty]);

  const addCartItems = async (sku: string, qty: number) => {
    setLoading(true);
    try {
      const cartResponse = handleCartResponseLogic(await add_cart_item(sku, qty), t('item_added_successfully'));
      if (cartResponse?.price_change_products && cartResponse?.price_change_products.length > 0) {
        setShowModal(true);
      }
    } catch (e: any) {
      console.log(e);
      apolloErrorToast({
        message: e?.response?.data?.message,
        Api: e?.config?.url ?? '',
        parameters: { name: sku ?? '' },
      });
    } finally {
      setLoading(false);
    }
  };
  const deleteCartItems = async (id: number | undefined, ids?: any) => {
    setLoading(true);
    try {
      const cartResponse = handleCartResponseLogic(await delete_cart_item(id, ids), t('item_deleted_successfully'));
      if (cartResponse?.price_change_products && cartResponse?.price_change_products.length > 0) {
        setShowModal(true);
      }
      return cartResponse;
    } catch (e: any) {
      console.log(e);
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
      return e;
    } finally {
      setLoading(false);
    }
  };

  const updateCartItems = async (item_id: number, qty: number, sku: string) => {
    setLoading(true);
    try {
      const cartResponse =  handleCartResponseLogic(await update_cart_item(item_id, qty, sku), t('cart_successfully_updated'));
      if (cartResponse?.price_change_products && cartResponse?.price_change_products.length > 0) {
        setShowModal(true);
      }
      return cartResponse;
    } catch (e: any) {
      console.log(e);
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
      return e;
    } finally {
      setLoading(false);
    }
  };

  const getCartData = async (loading = true) => {
    try {
      return handleCartResponseLogic(await get_cart());
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
      console.log(e);
    } finally {
      loading && setLoading(false);
    }
  };

  const applyStoreCredit = async (callback: Function) => {
    setLoading(true);
    try {
      handleCartResponseLogic(await apply_store_credit(), t('store_credit_applied'));
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      callback();
      setLoading(false);
    }
  };

  const deleteStoreCredit = async (callback: Function) => {
    setLoading(true);
    try {
      handleCartResponseLogic(await delete_store_credit(), t('store_credit_deleted'), callback);
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setLoading(false);
    }
  };

  const handleAddCoupon = async (
    couponCode: any,
    setCouponError: Function,
    setCouponSuccess: Function,
    callback: Function,
  ) => {
    if (couponCode) {
      setLoading(true);
      try {
        const res = await apply_coupon_code(couponCode);
        if (res?.status) {
          queryClient.invalidateQueries(['coupon']);
          successToast(t('coupon_applied'));
          setCouponSuccess(t('coupon_applied'));
          setCouponError('');
          setCartData(res.cart);
        } else {
          setCouponSuccess('');
          apolloErrorToast({ ...res, Api: '/cart/coupon-code' });
          setCouponError(t('invalid_coupon_code'));
        }
      } catch (err: any) {
        setCouponSuccess('');
        apolloErrorToast({ message: err?.response?.data?.message, Api: err?.config?.url ?? '' });
        setCouponError(err?.response?.data?.message);
      } finally {
        await callback();
        setLoading(false);
      }
    } else {
      setCouponError(t('enter_a_coupon_code'));
    }
  };

  const applyLukamPoints = async (amount: number, callBack: any) => {
    setLoading(true);
    try {
      handleCartResponseLogic(await apply_lukam_points(amount), t('lakum_points_applied'), callBack);
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setLoading(false);
    }
  };

  const removeLukamPoints = async (callBack: any) => {
    setLoading(true);
    try {
      handleCartResponseLogic(await remove_lukam_points(), t('lakum_points_deleted'), callBack);
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setLoading(false);
    }
  };

  const placeOrder = async (method?: string) => {
    if (method) paymentMethod = method;
    if (cartData?.totals?.total_segments?.find((segment: any) => segment.code === 'grand_total')?.value === 0)
      paymentMethod = 'free';
    if (!paymentMethod) {
      errorToast(t('select_payment'));
      return;
    }
    if (paymentMethod === 'aps_fort_cc' && !isEmpty(ccError)) {
      if (ccError?.card_security_code) errorToast(t('please_enter_a_valid_cvv'));
      if (ccError?.card_number) errorToast(t('please_enter_a_valid_card_number'));
      if (ccError?.card_holder_name) errorToast(t('please_enter_a_valid_card_holder_name'));
      if (ccError?.expiry_date) errorToast(t('please_enter_a_valid_expiry_date'));
      document.getElementById('payment-form')?.scrollIntoView(true);
      return;
    }
    let options: any = {};
    if (paymentMethod === 'aps_fort_cc') {
      if (activeCard?.isVault) {
        if (activeCard.public_hash && isEmpty(ccError)) {
          paymentMethod = 'aps_fort_vault';
          options = {
            payfort_vault_parameters: {
              public_hash: activeCard.public_hash,
              cvv: activeCard.card_security_code,
            },
          };
        } else {
          errorToast(t('please_enter_a_valid_cvv'));
          document.getElementById('payment-form')?.scrollIntoView(true);
          return;
        }
      } else {
        options.saved_card = activeCard?.remember_me;
      }
    }
    setLoading(true);
    try {
      const result = await place_order(paymentMethod, options);
      setOrderResult(result);
      setCartData((prev: any) => ({ ...prev, order: result.order, order_id: result.order_id }));
      if (paymentMethod === 'aps_fort_cc' || paymentMethod === 'aps_fort_vault') {
        return Payfort().redirect(result, activeCard, locale);
      }
      if (paymentMethod === 'tamara_pay_by_instalments') {
        return Payfort().redirect(result, undefined, locale);
      }
      push('/checkout/purchase');
    } catch (e: any) {
      if (e?.response?.data?.message === 'error_price_update') {
        setShowModal(true);
        const cartResponse = handleCartResponseLogic(await get_cart());
        setCartData(cartResponse);
      }else{
        apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
      }
    } finally {
      setLoading(false);
    }
  };

  const removeStoreCredit = async () => {
    setLoading(true);
    try {
      handleCartResponseLogic(await remove_store_credit(), t('store_credit_deleted'));
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setLoading(false);
    }
  };

  const removeCoupon = async (callback: Function, refetchPaymentMethod: Function) => {
    setLoading(true);
    try {
      handleCartResponseLogic(await remove_coupon_code(), t('coupon_code_deleted'), () => callback(''));
    } catch (e: any) {
      console.log('err', e);
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      refetchPaymentMethod();
      setLoading(false);
    }
  };

  const updatePaymentMethod = async (payload: any, callback: Function) => {
    setPaymentMethodsLoading(true);
    try {
      const res = await update_payment_methods(payload);
      console.log(res);
      setCartData(res?.cart);
      callback();
    } catch (e: any) {
      console.log('err', e);
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setPaymentMethodsLoading(false);
    }
  };

  const availableShippingMethods = async (payload: {
    latitude: string;
    longitude: string;
    city: string;
    country_id: string;
  }) => {
    setShippingMethodLoading(true);
    try {
      const res = await available_shipping_methods({
        ...payload,
        latitude: payload.latitude?.toString(),
        longitude: payload.longitude?.toString(),
      });
      setDeliveryMethods(
        res?.available_shipping_methods.length > 0
          ? res?.available_shipping_methods
          : 'we_are_not_serving_this_area_yet',
      );
    } catch (e: any) {
      console.log('err', e);
      setDeliveryMethods([]);
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setShippingMethodLoading(false);
    }
  };

  const checkCartStock = async (callback: any) => {
    setLoading(true);
    try {
      const cartResponse = handleCartResponseLogic(await check_cart_stock(), undefined, callback);
      if (cartResponse.message === 'error_price_update'){
        setShowModal(true);
        setModalRoute('/checkout/delivery-method');
      }
      return cartResponse;
    } catch (err: any) {
      console.log('err', err);
      if (err?.response?.data?.message === 'error_price_update'){
        setShowModal(true);
        setModalRoute('/checkout/delivery-method');
      }else {
        apolloErrorToast({ message: err?.response?.data?.message, Api: err?.config?.url ?? '' });
      }
      setLoading(false);
      throw err;
    }
  };

  const addNote = async (note: string, callBack?: Function) => {
    setLoading(true);
    try {
      handleCartResponseLogic(await add_a_note(note), undefined, callBack);
    } catch (err: any) {
      console.log('err', err);
      apolloErrorToast({ message: err?.response?.data?.message, Api: err?.config?.url ?? '' });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const pickLocationInfo = async (
    payload: { latitude: string; longitude: string; city: string; sub_inventory_code: string },
    req?: any,
  ) => {
    setShippingMethodLoading(true);
    try {
      const res = await pick_location_info(
        {
          ...payload,
          latitude: payload.latitude?.toString(),
          longitude: payload.longitude?.toString(),
        },
        req,
      );
      setDeliveryMethods((prev: any) => {
        const newArr = [...prev];
        const index = newArr.findIndex((item: any) => item.code === res?.code);
        newArr[index] = res;
        return newArr;
      });
    } catch (e: any) {
      apolloErrorToast({ message: e?.response?.data?.message, Api: e?.config?.url ?? '' });
    } finally {
      setShippingMethodLoading(false);
    }
  };

  const handleCartResponseLogic = <T extends Cart | ActionCart>(
    cartResponse: T,
    successToastMessage?: string | null,
    callBack?: Function,
  ) => {
    if (!isCart(cartResponse) && !cartResponse.status) {
      if (cartResponse?.message !== 'error_price_update'){
        apolloErrorToast({ ...cartResponse, Api: CartApiUrls.Base });
      }
      setCartData(cartResponse.cart);
      setLoading(false);
      return cartResponse;
    }

    const cart = !isCart(cartResponse) ? cartResponse.cart : cartResponse;
    if (!cartResponse?.price_change_products) {
      successToastMessage && successToast(successToastMessage);
    }
    callBack && callBack();
    setCartData(cart);
    setIsLoggin(!cart.customer_is_guest);
    return cartResponse;
  };

  const isCart = (cartResponse: Cart | ActionCart): cartResponse is Cart => {
    return (cartResponse as ActionCart)?.cart === undefined;
  };



  return {
    loading,
    setLoading,
    cartData,
    isGuest: cartData?.customer_is_guest,
    addCartItems,
    deleteCartItems,
    updateCartItems,
    getCartData,
    setCartData,
    applyLukamPoints,
    handleAddCoupon,
    removeLukamPoints,
    applyStoreCredit,
    placeOrder,
    deleteStoreCredit,
    cartItems,
    removeCoupon,
    removeStoreCredit,
    availableShippingMethods,
    pickLocationInfo,
    updatePaymentMethod,
    checkCartStock,
    addNote,
    showModal,
    setShowModal
  };
};

export default useCart;
