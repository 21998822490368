import { useQuery } from '@tanstack/react-query';
import { get_store_credit } from 'models/cart';
import useCustomer from 'hooks/useCustomer';
import useStoreView from 'hooks/useStoreView';
import { useTranslation } from 'react-i18next';

export default function useStoreCreditQuery(options?: any, isEnabled?: boolean) {
  const { customerData } = useCustomer();
  const [storeView] = useStoreView();
  const { t } = useTranslation('common');

  return useQuery(
    ['store-credit', storeView, customerData?.id],
    async () => {
      const data = await get_store_credit();

      return {
        currency: data?.currency || `${t('SAR')}`,
        value: data?.value || 0,
        ...data,
      };
    },
    {
      enabled: isEnabled && !!customerData?.id,
      staleTime: 1000 * 60 * 5,
      ...options,
    },
  );
}
