import dynamic from 'next/dynamic';
import HomeIcon from 'public/image/home-ic.svg';
import CategoryIcon from 'public/image/cat-ic.svg';
import SupportIcon from 'public/image/help-ic.svg';
import AccountIcon from 'public/image/account-ic.svg';
import { ShoppingCartIcon } from 'components/SvgIcons/ShoppingCartIcon';

const MobileNavItem = dynamic(() => import('./MobileMavMenuItem'), { ssr: false });

const mobileNavItems = [
  {
    key: 'menu-home',
    title: 'home',
    icon: <HomeIcon />,
    href: '/',
  },
  {
    key: 'menu-categories',
    title: 'categories',
    icon: <CategoryIcon />,
    href: '#',
  },
  {
    key: 'menu-cart',
    icon: <ShoppingCartIcon />,
    href: '/checkout',
  },
  {
    key: 'menu-support',
    title: 'support',
    icon: <SupportIcon />,
    href: '/support',
  },
  {
    key: 'menu-account',
    title: 'my_account',
    icon: <AccountIcon />,
    href: '/profile',
  },
];

const MobileNavs = ({ t }: { t: (data: any) => string }) => {
  return (
    <nav className='grid lg:hidden fixed bottom-0 z-[100] grid-cols-5 bg-white py-2 w-full'>
      {mobileNavItems.map((item) => {
        return <MobileNavItem href={item.href} key={item.key} id={item.key} title={t(item.title)} icon={item.icon} />;
      })}
    </nav>
  );
};

export default MobileNavs;
