export const LoaderIcon = ({ width = '221px', height = '221px' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    style={{
      margin: 'auto',
      display: 'block',
      shapeRendering: 'auto',
    }}
    width={width}
    height={height}
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
  >
    <g transform='rotate(0 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-1.0980392156862746s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(24 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-1.0196078431372548s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(48 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.9411764705882353s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(72 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.8627450980392157s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(96 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.7843137254901961s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(120 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.7058823529411765s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(144 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.6274509803921569s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(168 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.5490196078431373s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(192 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.47058823529411764s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(216 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.39215686274509803s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(240 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.3137254901960784s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(264 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.23529411764705882s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(288 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.1568627450980392s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(312 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#35b050'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='-0.0784313725490196s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
    <g transform='rotate(336 50 50)'>
      <rect x='48' y='27' rx='0' ry='0' width='4' height='10' fill='#020621'>
        <animate
          attributeName='opacity'
          values='1;0'
          keyTimes='0;1'
          dur='1.1764705882352942s'
          begin='0s'
          repeatCount='indefinite'
        ></animate>
      </rect>
    </g>
  </svg>
);
