import { IProductItem } from 'lib/interface';
import VerticalItem from 'components/Product/Vertical';
import HorizontalItem from 'components/Product/Horizontal';
import { useRouter } from 'next/router';
import SearchListItem from './Product/SearchListItem';

interface FilterData {
  [key: string]: string[];
}
interface IProductItemProps {
  product: IProductItem;
  horizontal?: boolean;
  className?: string;
  bg?: string;
  cartAble?: boolean;
  refundable?: any;
  refundQty?: any;
  item_list_name?: string;
  cart?: boolean;
  algoliaQueryId?: string;
  index: number;
  searchList?: boolean;
  filter?: FilterData;
  is_prescription?: any;
  setShowResults?: any;
}

export default function ProductItem({
  horizontal,
  className,
  product,
  bg,
  searchList,
  cartAble = true,
  refundable = null,
  refundQty = null,
  item_list_name,
  cart = false,
  algoliaQueryId,
  index = 0,
  filter,
  setShowResults,
  is_prescription,
}: IProductItemProps) {
  const { locale } = useRouter();

  if (horizontal) {
    return (
      <HorizontalItem
        className={className}
        product={product}
        bg={bg}
        cartAble={cartAble}
        refundable={refundable}
        is_prescription={is_prescription}
        refundQty={refundQty}
        item_list_name={item_list_name ?? product?.name ?? ''}
        cart={cart}
      />
    );
  }
  const productNameLoacalSelector = locale?.split('-')[0] || 'en';
  const prodName = typeof product?.name === 'object' ? product?.name[productNameLoacalSelector] : product?.name;

  if (searchList) {
    return (
      <SearchListItem
        setShowResults={setShowResults}
        className={className}
        product={product}
        bg={bg}
        item_list_name={prodName ?? ''}
        cart={cart}
        algoliaQueryId={algoliaQueryId ?? ''}
        index={index}
        filter={filter ?? {}}
      />
    );
  }
  return (
    <VerticalItem
      setShowResults={setShowResults}
      className={className}
      product={product}
      bg={bg}
      item_list_name={prodName ?? ''}
      cart={cart}
      algoliaQueryId={algoliaQueryId ?? ''}
      index={index}
      filter={filter ?? {}}
    />
  );
}
