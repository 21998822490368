import { Button } from 'components/Button';
import { useRouter } from 'next/router';
import LeftCaret from 'public/image/caret-left.svg';
import RightCaret from 'public/image/right-caret-white.svg';
import ErrorLogo from 'public/image/error.svg';
import { useTranslation } from 'react-i18next';

const ErrorPage = ({ onClick }: { onClick?: any }) => {
  const { push } = useRouter();
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return (
    <div className='w-[90%] lg:w-[80%] py-10 m-auto flex flex-col justify-center'>
      <div className='mx-auto text-center'>
        <h1 className='text-[50px] font-bold text-gray-900 text-center'>{t('oops')}</h1>
        <span className='text-gray-900'>{t('you_are_lost')}</span>
      </div>
      <div className='flex justify-center w-full my-5'>
        <ErrorLogo />
      </div>
      <div className='mx-auto lg:mt-6 '>
        <Button
          onClick={() =>
            push('/').finally(() => {
              onClick && onClick();
              window.location.reload();
            })
          }
          icon={locale === 'ar-sa' ? <RightCaret /> : <LeftCaret />}
          className='px-6'
          title={t('go_home') || ' '}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
