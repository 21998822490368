import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useStoreView() {
  const router = useRouter();
  const [storeView, setStoreView] = useLocalStorage('storeView', 'alhabib_en');
  useEffect(() => {
    const storeView = 'alhabib_' + (router.locale?.replaceAll('"', '').split('-')[0] || 'en');
    setStoreView(storeView);
  }, [router.locale]);

  return [storeView, setStoreView];
}
