import nookies from 'nookies';

export const setCookiesWithNookies = (key: string, value: string) => {
  nookies.set(null, key, value, {
    maxAge: 60 * 60 * 24,
    path: '/',
    SameSite: 'None',
    Secure: true,
    httpOnly: false,
    secure: true,
    sameSite: 'none',
    encode: (v) => v,
  });
};
