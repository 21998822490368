import cookie from 'cookie';
import { useState } from 'react';

// @ts-ignore
export type Encode = cookie.CookieSerializeOptions;

// @ts-ignore
export type Decode = cookie.CookieParseOptions;

type Options = {
  decode?: Decode;
  encode?: Encode;
  maxAge: number;
  path: string;
  SameSite: string;
  Secure: boolean;
  httpOnly: boolean;
};

type GetCookieValue<T> = {
  key: string;
  cookies: string;
  options: Decode | undefined;
  defaultValue: T;
};

function getCookieValue<T>({ key, cookies, options, defaultValue }: GetCookieValue<T>): string | T {
  const value = cookie.parse(cookies || '', options);

  return value[key] ?? defaultValue;
}

const future = new Date();
future.setDate(future.getDate() + 300);

const defaultEncode: Encode = { path: '/', expires: future };

function useCookieState<T = string>(
  key: string,
  initialValue: T,
  options?: Options,
): [T, (value: T, encode?: Encode) => void] {
  const getInitialValue = (): T => {
    const defaultValue = typeof initialValue === 'function' ? initialValue() : initialValue;

    if (typeof window === 'undefined') return defaultValue;

    return getCookieValue({
      key,
      cookies: document.cookie,
      options: options?.decode,
      defaultValue,
    }) as T;
  };

  const [value, setValue] = useState<T>(getInitialValue);

  const setNextValue = (value: T, encode?: Encode) => {
    const stringified = typeof value === 'string' ? value : JSON.stringify(value);

    document.cookie = cookie.serialize(key, stringified, Object.assign({}, defaultEncode, options?.encode, encode));

    setValue(value);
  };

  return [value, setNextValue];
}

export default function useCookieStorage(keyName: string, initialValue: any, options?: any) {
  initialValue = JSON.stringify(initialValue);
  let [rawValue, setRawValue] = useState(initialValue);
  try {
    [rawValue, setRawValue] = useCookieState(keyName, initialValue, {
      maxAge: 60 * 60 * 24 * 15,
      path: '/',
      httpOnly: false,
      Secure: true,
      SameSite: 'none',
      encode: (v) => v,
    });
  } catch (e) {
    console.error(e);
  }
  let value;
  try {
    value = JSON.parse(rawValue);
  } catch (e) {
    value = rawValue;
  }
  const setValue = (value: any) => setRawValue(JSON.stringify(value));

  return [value, setValue];
}
