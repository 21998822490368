export const AddCartIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z'
        fill='white'
      />
      <path
        d='M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z'
        fill='white'
      />
      <path
        d='M21 7.00001H7.334L6.18 4.23001C6.02851 3.86515 5.77211 3.55348 5.4433 3.33451C5.11448 3.11553 4.72806 2.99911 4.333 3.00001H2V5.00001H4.334L9.077 16.385C9.232 16.757 9.597 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.35201C21.9932 8.2006 22.012 8.03786 21.9918 7.87763C21.9716 7.7174 21.913 7.56441 21.8211 7.43167C21.7291 7.29893 21.6064 7.19037 21.4634 7.11522C21.3205 7.04006 21.1615 7.00054 21 7.00001ZM17 13H15V15H13V13H11V11H13V9.00001H15V11H17V13Z'
        fill='white'
      />
    </svg>
  );
};
