import useStoreView from 'hooks/useStoreView';
import { useQuery } from '@tanstack/react-query';
import { getCategories } from 'models/catalog';

export const useGetCatalog = (renderCategories = true) => {
  const [storeView] = useStoreView();
  return useQuery(
    ['catalogs', storeView],
    async () => {
      return await getCategories();
    },
    {
      enabled: renderCategories,
      staleTime: 1000 * 60 * 5,
    },
  );
};
