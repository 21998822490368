import React, { useContext, useEffect, useMemo, useState } from 'react';
import SideBarMenu from 'components/SideBarMenu';
import { useRouter } from 'next/router';
import useCustomer from 'hooks/useCustomer';
import Link from 'next/link';
import clsx from 'clsx';
import { LogoutIcon } from 'components/SvgIcons/LogoutIcon';
import useCookieStorage from 'hooks/useCookieStorage';
import { DeviceDetector } from 'utils/dd';
import { BackButton } from 'components/SideBar/BackButton';
import { BreadCrumb } from 'components/SideBar/BreadCrumb';
import { MobileHeader } from './MobileHeader';
import { GuestUserButton } from './GuestUserButton';
import { WelcomeMessage } from './WelcomeMessage';
import { LakumPoints } from './LakumPoints';
import { StoreCredits } from './StoreCredits';
import useRtl from 'hooks/useRtl';
import { LanguageType } from 'lib/constants';

interface Page {
  children?: React.ReactNode;
  title?: string;
  breadcrumb?: string;
  t: any;
  sideBarClassName?: string;
  sideBarBackClassName?: string;
  backColor?: string;
}

const SideBar = ({
  children,
  title,
  breadcrumb,
  t,
  sideBarClassName = 'bg-[#fff] px-2',
  sideBarBackClassName = 'w-full mb-2 grid border-b grid-cols-[10%_80%] items-center px-2 h-[40px] bg-[#fff] text-gray-900',
  backColor,
}: Page) => {
  const { push, asPath, pathname, back, locale } = useRouter();
  const { logoutUser, customerData } = useCustomer();
  const isMobile = useContext(DeviceDetector).isMobile;
  const [isCustomerLoggedIn] = useCookieStorage('isCustomerLoggedIn', false);
  const [name, setName] = useState('');
  const isRtl = useRtl();

  const renderWelcomMessage = useMemo(() => {
    return <WelcomeMessage name={name} t={t} />;
  }, [name]);

  useEffect(() => {
    const name = customerData?.firstname ?? '';
    setName(name);
  }, [customerData]);

  useEffect(() => {
    if (isMobile) {
      const div = document.getElementById('sidebar-content');
      div && div.scrollIntoView({ behavior: 'smooth' });
    }
  }, [asPath, isMobile]);

  const handelCustomProfileBack = () => {
    push(`/${locale}/profile`);
  };

  if (isMobile && pathname !== '/profile') {
    return (
      <BackButton
        t={t}
        back={pathname.includes('/profile') ? handelCustomProfileBack : back}
        title={title}
        backColor={backColor}
        sideBarBackClassName={sideBarBackClassName}
        sideBarClassName={sideBarClassName}
      >
        {children}
      </BackButton>
    );
  }

  if (['/profile/orders/[id]', '/profile/prescriptions/[id]', '/profile/orders/[id]/return-items'].includes(pathname))
    return children;

  return (
    <div
      className={clsx(
        'w-full bg-apollo-white',
        isMobile && 'overflow-y-auto pb-[30%] w-[100vw] h-[calc(100vh)] bg-apollo-dark-grey',
      )}
    >
      {!isMobile && <BreadCrumb t={t} title={title} breadcrumb={breadcrumb} />}
      {/* Content start*/}
      <div className='grid w-full overflow-y-auto lg:grid-cols-[30%_65%] lg:w-[80%] lg:mx-auto gap-8'>
        <div className={`w-full ${isMobile && 'mb-20'}`}>
          <div className='lg:bg-apollo-dark-white rounded lg:py-4'>
            {isMobile && <MobileHeader />}
            {isMobile && !isCustomerLoggedIn && <GuestUserButton t={t} push={push} />}
            {renderWelcomMessage}
            {isCustomerLoggedIn && (
              <div className='flex justify-between my-8 w-[90%] mx-auto'>
                <div>
                  <div className='text-[17px] text-apollo-secondary font-bold'>{t('lakum')}</div>
                  <Link href={'/support/lakum_terms'} prefetch={false}>
                    <a className='text-[14px] text-apollo-light-gray cursor-pointer'>{`${t('learn_more')}`}</a>
                  </Link>
                </div>
                <LakumPoints t={t} pathname={pathname} push={push} isMobile={isMobile} />
              </div>
            )}
            {isCustomerLoggedIn && <StoreCredits t={t} isMobile={isMobile} />}
            <div className='w-[90%] mx-auto'>
              <SideBarMenu t={t} />
            </div>
            {isCustomerLoggedIn && (
              <div
                className='text-[17px] w-[90%] mx-auto mt-2 mb-2 font-bold font-primary text-apollo-error cursor-pointer'
                onClick={async () => {
                  logoutUser(push).then(() => {
                    window.location.href = `/${isRtl ? LanguageType.Ar : LanguageType.En}/login-with-number`;
                  });
                }}
              >
                <LogoutIcon /> {t('logout_from_your_account')}
              </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className='w-full' id='sidebar-content'>
            {children}
          </div>
        )}
      </div>
      {/* Content End*/}
    </div>
  );
};

export default SideBar;
