export const PrescriptionIconTwo = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.23593 7.23715C2.08253 6.5897 2.42678 5.93113 3.04609 5.68787C5.82002 4.59827 8.84025 4 12 4C15.1598 4 18.18 4.59827 20.9539 5.68787C21.5732 5.93113 21.9175 6.5897 21.7641 7.23715C20.4416 12.8185 17.2181 17.6627 12.8164 21.0459C12.3344 21.4164 11.6656 21.4164 11.1836 21.0459C6.78188 17.6627 3.55836 12.8185 2.23593 7.23715Z'
        stroke='white'
        strokeWidth='2'
      />
      <path
        d='M8.79688 11.7779L11.3459 14.0655L15.2021 8.70605'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
