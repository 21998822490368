const normalizeSrc = (src) => {
  let replaceURL = src?.split('/media/')[0];
  src = src?.replace(
    replaceURL,
    // @ts-ignore
    process.env.NEXT_PUBLIC_NEXTJS_S3_BUCKET ||
      'https://s3.me-south-1.amazonaws.com/beta-bucket.alhabibpharmacydev.com',
  );
  return src.startsWith('/') ? src.slice(1) : src;
};

export function cloudflareLoader({ src, width, quality }) {
  if (src?.startsWith('/local/')) return src?.replace('/local/', '/');
  let domain = 'https://alhabibpharmacy.com';
  const params = [`width=${width}`, 'format=auto'];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');
  return `${domain}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
}
