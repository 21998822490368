import { addToCartEvent, removeFromCartEvent } from 'lib/helpers/gtm/events';
import { MinusCartItemIcon } from 'components/SvgIcons/MinusCartItemIcon';
import { PlusCartItemIcon } from 'components/SvgIcons/PlusCartItemIcon';

import useCart from 'hooks/useCart';
import { IProductItem } from 'lib/interface';
import { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import RemoveIcon from 'public/image/remove-icon.svg';
import { useLoading } from 'hooks/useLoading';

interface IQuantityButtonsProps {
  product: IProductItem;
  isVertical?: boolean;
}

export default function QuantityButtons({ product, isVertical }: IQuantityButtonsProps) {
  const [quantity, setQuantity] = useState<number | any>(product?.qty || 0);
  const [loading] = useLoading();
  const [type, setType] = useState('');
  const debouncedQuantity = useDebounce(quantity, 0);

  const { deleteCartItems, updateCartItems } = useCart();
  const handleUpdateProductQuantity = async (qty: number) => {
    if (type) {
      try {
        if (type === 'update' && product?.item_id && product?.sku) {
          const res = await updateCartItems(product?.item_id, qty, product?.sku);
          if (product?.qty && product?.qty > qty) removeFromCartEvent(product);
          else await addToCartEvent(product);
          const updatedQty = res?.items?.find((itm) => itm?.item_id === product?.item_id)?.qty;
          if (updatedQty) {
            setQuantity(updatedQty);
          } else {
            setQuantity(product.qty as number);
          }
        }
        if (type === 'remove' && product?.item_id) {
          const res = await deleteCartItems(product?.item_id);
          removeFromCartEvent(product);
          const updatedQty = res?.items?.find((itm) => itm?.item_id === product?.item_id)?.qty;
          if (updatedQty) {
            setQuantity(updatedQty);
          } else {
            setQuantity(product.qty as number);
          }
        }
      } catch (e) {
        //
      } finally {
        setType('');
      }
    }
  };

  useEffect(() => {
    handleUpdateProductQuantity(debouncedQuantity);
  }, [debouncedQuantity]);

  useEffect(() => {
    if (product) setQuantity(product?.qty);
  }, [product?.qty]);

  const isCustomerAddedMoreThanInStock = product?.salable_stock <= quantity;

  if ((product?.cartAbleWithPlusMinus && product?.item_id) || isVertical) {
    return (
      <span className='inline-flex items-center rounded-lg justify-center bg-none'>
        <button
          disabled={loading}
          className={`${
            product?.qty === 0 ? '' : 'cursor-pointer'
          } p-1 bg-icon-color rtl:rounded-r-lg rounded-l-lg rtl:rounded-l-none`}
          onClick={() => {
            setQuantity((prevState) => {
              if (prevState < 1) {
                return 0;
              } else {
                return prevState - 1;
              }
            });
            if (quantity && quantity > 1) {
              setType('update');
            } else {
              setType('remove');
            }
          }}
        >
          {product.qty === 1 ? <RemoveIcon /> : <MinusCartItemIcon />}
        </button>
        <span className='pl-3 px-3 text-[20px]'>{quantity}</span>
        <button
          disabled={loading || isCustomerAddedMoreThanInStock}
          className={`${
            isCustomerAddedMoreThanInStock ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'
          } p-1 bg-icon-color rtl:rounded-l-lg rounded-r-lg rtl:rounded-r-none`}
          onClick={() => {
            if (product.qty && product.item_id) {
              setQuantity((prevState) => (prevState < product.salable_stock ? prevState + 1 : prevState));
              setType('update');
            }
          }}
        >
          <PlusCartItemIcon color={'#35B050'} signColor={'#FFFF'} />
        </button>
      </span>
    );
  }
  return <></>;
}
