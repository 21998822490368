export const BreadCrumb = ({ t, title, breadcrumb }) => {
  return (
    <div className='grid grid-cols-12 py-4 pl-4'>
      <div className='col-start-2 col-span-10'>
        <div className='text-[28px]'>{t(title)}</div>
        <div className='text-[20px] text-apollo-light-gray'>{`${t('home')} > ${t('my_account')} > ${t(
          breadcrumb,
        )}`}</div>
      </div>
    </div>
  );
};
