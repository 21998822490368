import useCart from 'hooks/useCart';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { ProductItemDiscountRibbon } from 'components/Widgets/ProductItemDiscountRibbon';
import { Button } from 'components/Button';
import { addToCartEvent, selectContentEvent } from 'lib/helpers/gtm/events';
import { IProductItem } from 'lib/interface';
import { productUrlGenerator, productUrlGeneratorWithUrlKey } from 'utils/product-url';
import { PrescriptionIconTwo } from 'components/SvgIcons/PrescriptionIconTwo';
import { AddCartIcon } from 'components/SvgIcons/AddCartIcon';
import PharmacyImage from 'components/PharmacyImage';
import useSessionStorage from 'hooks/useSessionStorage';
import QuantityButtons from './QuantityButtons';
import { useRouter } from 'next/router';
import useCustomer from 'hooks/useCustomer';
import useLocalStorage from 'hooks/useLocalStorage';
import { generateConvertedFiltersArray } from 'utils/convert-algolia-facets';
import useRtl from 'hooks/useRtl';
import { AlgoliaEventNames, AlgoliaEventTypes } from 'lib/constants';
import CustomSearchListBadges from './Badges-plp-searchlist-custom';
import { useAlgolia } from '../../context/algolia-context';

export interface FilterData {
  [key: string]: string[];
}

interface IProductItemProps {
  product: IProductItem;
  horizontal?: boolean;
  className?: string;
  bg?: string;
  item_list_name?: string;
  cart?: boolean;
  algoliaQueryId: string;
  index: number;
  filter: FilterData;
  setShowResults: any;
}

export default function SearchListItem({
  className,
  product,
  bg = 'bg-white',
  item_list_name,
  cart = false,
  algoliaQueryId,
  index,
  setShowResults,
  filter,
}: IProductItemProps) {
  const algolia = useAlgolia();
  const isRtl = useRtl();
  const { cartData, addCartItems, deleteCartItems } = useCart();
  const [showCountdown] = useSessionStorage('pdpCountdown', true);
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const { customerData } = useCustomer();
  const hasImage = product?.media?.image ? product?.media?.image[0]?.original : product?.image;
  const productNameLoacalSelector = locale?.split('-')[0] || 'en';
  const prodName = typeof product?.name === 'object' ? product?.name[productNameLoacalSelector] : product?.name;
  const [, setAlgoliaProductQueryIds] = useLocalStorage<any>('algoliaProductQueryIds', {});
  let offerTag;
  if (product?.labels?.plp?.length > 0) {
    offerTag = product?.labels?.plp?.sort((a, b) => a?.priority - b?.priority)[0];
  }

  const plp = product?.labels?.plp || [];
  if (product?.labels?.plp?.length > 0) {
  }
  const findOffer = (position: string) => {
    return plp?.filter((item: any) => item?.position === position);
  };
  const above_OfferTags = plp && plp?.length > 0 ? findOffer('above_image') : [];
  const below_OfferTags = plp && plp?.length > 0 ? findOffer('below_image') : [];
  const top_OfferTags = plp && plp?.length > 0 ? findOffer('top') : [];
  const top_left_OfferTags = plp && plp?.length > 0 ? findOffer('top_left') : [];
  const top_right_OfferTags = plp && plp?.length > 0 ? findOffer('top_right') : [];
  const bottom_OfferTags = plp && plp?.length > 0 ? findOffer('bottom') : [];
  const bottom_left_OfferTags = plp && plp?.length > 0 ? findOffer('bottom_left') : [];
  const bottom_right_OfferTags = plp && plp?.length > 0 ? findOffer('bottom_right') : [];
  const center_OfferTags = plp && plp?.length > 0 ? findOffer('center') : [];
  const center_left_OfferTags = plp && plp?.length > 0 ? findOffer('center_left') : [];
  const center_right_OfferTags = plp && plp?.length > 0 ? findOffer('center_right') : [];

  const isProductInCart = cartData?.items?.find((item: any) => item.sku == product?.sku);
  const user_id: string = customerData?.id ? String(customerData?.id) : 'guest';

  const prescriptionStatus = product?.attributes?.rx === true || product?.attributes?.rx?.value === true;

  return (
    <div className={`max-w-[100%] flex flex-row gap-1 justify-between rounded ${className}`}>
      {
        <>
          <Link
            className={`${cart ? 'pointer-events-none' : ''}`}
            href={
              cart
                ? '#'
                : productUrlGeneratorWithUrlKey(
                    typeof product?.url_key === 'object' ? product.url_key[isRtl ? 'ar' : 'en'] : product?.url_key,
                  )
            }
          >
            <a
              className='cursor-pointer'
              onClick={() => {
                setShowResults(false);
                selectContentEvent([product], item_list_name);
                if (algoliaQueryId) {
                  algolia.sendEvents(AlgoliaEventTypes.clickedObjectIDsAfterSearch, {
                    userToken: user_id,
                    eventName: AlgoliaEventNames.clickedObjectIDsAfterSearch,
                    index: algolia.productIndexName,
                    queryID: algoliaQueryId,
                    objectIDs: [String(product?.entity_id)],
                    positions: [index],
                  });
                }
              }}
            >
              <div
                className={`relative rounded-lg flex justify-center bg-white border-[#ececec] w-[120px] max-w-[120px] min-w-[120px] border-[1px]   ${
                  !hasImage && 'bg-dark-white'
                }`}
              >
                <PharmacyImage
                  imageWidth={120}
                  imageHeight={100}
                  src={hasImage || '/local/image/placeholder_new.png'}
                  objectFit='contain'
                  localImage={hasImage ? false : true}
                  alt={prodName}
                />
                <CustomSearchListBadges OfferTags={above_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={below_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={top_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={top_left_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={top_right_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={bottom_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={bottom_left_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={bottom_right_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={center_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={center_left_OfferTags} t={t} />
                <CustomSearchListBadges OfferTags={center_right_OfferTags} t={t} />
              </div>

              {product?.status === 'prescription' && (
                <div className='flex items-center'>
                  <div className='rounded bg-[#0396E8] p-1 w-8 text-caption-2 text-white text-center ml-2'>
                    <PrescriptionIconTwo />
                  </div>
                </div>
              )}
            </a>
          </Link>
          <div className='flex flex-col gap-1 min-w-[65%]'>
            <p
              className={`group flex ${
                product?.original_price?.with_tax_rounded ? 'h-[19px]' : 'h-[35px]'
              } overflow-hidden mt-[4px] text-start rtl:text-right text-gray-900 font-[600] font-Montserrat leading-[18px] text-[13px]`}
            >
              <span className=''>
                {truncateText(prodName || '', product?.original_price?.with_tax_rounded ? 20 : 35)}
              </span>
            </p>
            <div className='flex flex-row place-content-between items-center max-h-[50px] w-[100%] min-w-[60%]'>
              <div>
                {product?.original_price?.with_tax_rounded && (
                  <ProductItemDiscountRibbon
                    percent={1 - product?.price?.with_tax_rounded / product?.original_price?.with_tax_rounded}
                  />
                )}
                <div className='flex flex-col justify-start rtl:justify-end'>
                  {product?.price?.with_tax_rounded && (
                    <p
                      style={{
                        fontSize: product?.original_price?.with_tax_rounded ? '14px' : '17px',
                      }}
                      className={`text-headline font-bold text-dark-gray`}
                    >
                      {product?.price?.with_tax_rounded.toFixed(2)} <span className='rtl:text-[9px]'>{t('SAR')}</span>
                    </p>
                  )}
                  {product?.original_price?.with_tax_rounded && (
                    <div className='flex'>
                      <span className='block font-bold line-through text-caption-1 text-light-gray'>
                        {product?.original_price?.with_tax_rounded} {t('SAR')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className={` ${product?.status == 'in-stock' && !prescriptionStatus ? '' : 'mb-[0px]'}`}>
                {(product?.status === 'disabled' || product?.status === 'out-of-stock') && !prescriptionStatus && (
                  <p className='font-bold text-left rtl:text-right text-caption-1 text-warning'>{t('out_of_stock')}</p>
                )}
                {product?.status === 'prescription' && (
                  <Button
                    title={t('see_prescription') || ''}
                    icon={<PrescriptionIconTwo />}
                    fullWidth
                    className='mt-2.5 bg-[#0396E8] border-[#0396E8]'
                    size='small'
                  />
                )}
                {/* {product?.attributes?.rx?.value ||
                  (product?.attributes?.rx && (
                    <>
                      <RxNotify />
                    </>
                  ))} */}
                {!prescriptionStatus &&
                  product?.status == 'in-stock' &&
                  (isProductInCart ? (
                    <div className='flex items-center justify-center'>
                      <QuantityButtons product={{ ...isProductInCart, ...isProductInCart?.product }} isVertical />
                    </div>
                  ) : (
                    <Button
                      title={t('add') || ''}
                      icon={<AddCartIcon />}
                      fullWidth
                      className='font-bold'
                      size='small'
                      onClick={() => {
                        addCartItems(product?.sku, 1).then(async () => {
                          await addToCartEvent(product);
                          if (algoliaQueryId) {
                            algolia.sendEvents(AlgoliaEventTypes.addedToCartObjectIDsAfterSearch, {
                              userToken: user_id,
                              eventName: AlgoliaEventNames.addedToCartObjectIDsAfterSearch,
                              index: algolia.productIndexName,
                              queryID: algoliaQueryId,
                              objectIDs: [`${product?.sku}`],
                              value:
                                product?.price?.with_tax_rounded ?? product?.price?.with_tax_rounded ?? product?.price,
                              currency: 'SAR',
                            });

                            if (filter && Object.keys(filter).length > 0) {
                              Object.keys(filter).map((item) => {
                                algolia.sendEvents(AlgoliaEventTypes.convertedFilters, {
                                  userToken: user_id,
                                  eventName: `${item} ${AlgoliaEventNames.convertedFilters}`,
                                  index: algolia.productIndexName,
                                  filters: generateConvertedFiltersArray(filter, isRtl)?.[item],
                                });
                              });
                            }
                            setAlgoliaProductQueryIds((prev) => ({ ...prev, [`${product?.sku}`]: algoliaQueryId }));
                          }
                        });
                      }}
                    />
                  ))}
                {prescriptionStatus && (
                  <p className='text-left rtl:text-right text-warning font-bold text-caption-1 mt-[1px]'>
                    {t('prescription_needed')}
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}
