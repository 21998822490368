import { NextPageContext } from 'next';
import { setCookie } from 'nookies';

export const getUserIp = (ctx: NextPageContext) => {
  const ip = ctx.req?.headers['x-forwarded-for'] || ctx.req?.socket.remoteAddress;

  setCookie(ctx ?? null, 'userIpAddress', Array.isArray(ip) ? ip[0] : (ip ?? ''), {
    maxAge: 60 * 60 * 24,
    path: '/',
    httpOnly: false,
    secure: true,
    sameSite: 'none',
    domain: new URL(process.env.NEXT_PUBLIC_NEXTJS_DOMAIN ?? '').hostname,
    encode: (v) => v,
  });

  return ip;
};
