import { del, get, post } from 'models/base';
import { Wishlist } from '../types/wishlist/wishlist.type';
import { AxiosResponse } from 'axios';

export const wishlist = async (req?: any): Promise<Wishlist> => {
  const { data } = await get('/wishlist', req);
  return data;
};

export const add_wishlist = async (id: number): Promise<AxiosResponse<void>> => post(`/wishlist/${id}`, {});

export const add_prescrepted_wishlist = async (id: number): Promise<AxiosResponse<void>> =>
  post(`/wishlist/sku/${id}`, {});

export const delete_wishlist = async (id: number): Promise<AxiosResponse<void>> => del(`/wishlist/${id}`);

export const delete_bulk_wishlist = async (items: number[]): Promise<AxiosResponse<Wishlist>> =>
  post('/wishlist/batch-delete', items);
