import clsx from 'clsx';
import { MouseEventHandler, forwardRef, useEffect, useState } from 'react';

export const Button = forwardRef(
  (
    props: {
      color?: 'primary' | 'white' | 'black' | 'transparent' | 'error' | 'warning';
      icon?: any;
      title?: string;
      size?: 'default' | 'small' | 'large';
      variant?: 'plain' | 'outline' | 'primary' | 'warning' | 'error' | 'error-outlined' | 'no-border' | 'gray';
      fullWidth?: boolean;
      disabled?: boolean;
      dark?: boolean;
      className?: string;
      textSize?: string;
      titleClass?: string;
      onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
      type?: 'submit' | 'reset' | 'button';
    },
    ref,
  ) => {
    const {
      color,
      className,
      icon,
      title,
      onClick,
      size,
      variant,
      disabled,
      dark,
      fullWidth,
      titleClass,
      textSize,
      type,
    } = props;
    const [buttonClass, setButtonClass] = useState('');
    const [spanClass, setSpanClass] = useState('');
    useEffect(() => {
      let btnClass = '';
      let textClass = '';
      let bgColor = '';
      switch (color) {
        case 'primary':
          bgColor = 'bg-primary';
          break;
        case 'warning':
          bgColor = 'bg-warning';
          break;
        case 'white':
          bgColor = 'bg-white';
          break;
        case 'error':
          bgColor = 'border-error bg-error';
          break;
        case 'black':
          bgColor = 'border-black bg-black';
          break;
        case 'transparent':
          break;
        default:
          bgColor = 'bg-primary';
          break;
      }
      switch (variant) {
        case 'plain':
          setButtonClass('border-transparent bg-transparent');
          btnClass = 'border-transparent bg-transparent';
          setSpanClass('text-primary');
          textClass = 'text-primary';
          break;
        case 'warning':
          setButtonClass('border-warning bg-warning');
          btnClass = 'border-warning bg-warning';
          setSpanClass('text-white');
          textClass = 'text-white';
          break;
        case 'gray':
          setButtonClass('border-light-gray bg-light-gray');
          btnClass = 'border-light-gray bg-light-gray';
          setSpanClass('text-white');
          textClass = 'text-white';
          break;
        case 'error':
          setButtonClass('border-error bg-error');
          btnClass = 'border-error bg-error';
          setSpanClass('text-white');
          textClass = 'text-white';
          break;
        case 'error-outlined':
          setButtonClass('border-error bg-white');
          btnClass = 'border-error bg-white';
          setSpanClass('text-error');
          textClass = 'text-error';
          break;
        case 'outline':
          if (color === 'black') {
            setButtonClass(bgColor + ' bg-transparent');
            btnClass = bgColor + ' bg-transparent';
            setSpanClass('text-black');
            textClass = 'text-black';
          } else {
            setButtonClass(bgColor + ' border-primary bg-transparent');
            btnClass = bgColor + ' border-primary bg-transparent';
            setSpanClass('text-primary');
            textClass = 'text-primary';
          }
          break;
        case 'no-border':
          setButtonClass(bgColor + ' border-none bg-transparent');
          btnClass = bgColor + ' border-none bg-transparent';
          break;
        default:
          setButtonClass(bgColor + ' border-primary hover:border-secondary hover:bg-secondary');
          btnClass = bgColor + ' border-primary hover:border-secondary hover:bg-secondary';
          setSpanClass('text-white');
          textClass = 'text-white';
          break;
      }
      switch (size) {
        case 'small':
          setButtonClass(btnClass + ' py-1.5 px-1 lg:px-1.5');

          setSpanClass(textClass + clsx(`text-caption-1 text-white text-[14px]`));
          break;
        case 'large':
          setButtonClass(btnClass + ' py-3.5 px-8 ');
          setSpanClass(textClass + ' text-body');
          break;
        default:
          setButtonClass(btnClass + ' py-2 px-1');
          setSpanClass(textClass + ' text-callout');
          break;
      }
      // eslint-disable-next-line
    }, [variant, size]);
    return (
      <button
        //@ts-ignore
        ref={ref}
        aria-label='button'
        type={type || 'button'}
        disabled={disabled}
        className={clsx(
          `inline-flex border-2 items-center gap-2 apollo-button rounded-lg disabled:opacity-40 disabled:pointer-events-none justify-center`,
          buttonClass,
          fullWidth ? 'w-full' : '',
          className ? className : 'text-[14px]',
        )}
        onClick={onClick}
      >
        {icon ? icon : <></>}
        {title ? (
          <span style={{ fontSize: `${textSize}px` }} className={`font-primary ${spanClass} ${titleClass}`}>
            {title}
          </span>
        ) : (
          <></>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
