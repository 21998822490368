import useCart from 'hooks/useCart';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { ProductItemDiscountRibbon } from 'components/Widgets/ProductItemDiscountRibbon';
import { Button } from 'components/Button';
import { addToCartEvent, selectContentEvent } from 'lib/helpers/gtm/events';
import { IProductItem } from 'lib/interface';
import { productUrlGeneratorWithUrlKey } from 'utils/product-url';
import { PrescriptionIconTwo } from 'components/SvgIcons/PrescriptionIconTwo';
import { AddCartIcon } from 'components/SvgIcons/AddCartIcon';
import PharmacyImage from 'components/PharmacyImage';
import useSessionStorage from 'hooks/useSessionStorage';
import QuantityButtons from './QuantityButtons';
import CustomBadges from './Badges-plp-custom';
import { useRouter } from 'next/router';
import useCustomer from 'hooks/useCustomer';
import useLocalStorage from 'hooks/useLocalStorage';
import { generateConvertedFiltersArray } from 'utils/convert-algolia-facets';
import useRtl from 'hooks/useRtl';
import { AlgoliaEventNames, AlgoliaEventTypes } from 'lib/constants';
import { useAlgolia } from '../../context/algolia-context';
import { useContext } from 'react';
import useWishlist from 'hooks/useWishlist';
import { DeviceDetector } from 'utils/dd';
import { AddToWishlistRoundedIcon } from 'components/SvgIcons/AddToWishlistIconRounded';
import { AddToWishlistRoundedSelectedIcon } from 'components/SvgIcons/AddToWishlistIconRoundedSelected';

export interface FilterData {
  [key: string]: string[];
}

interface IProductItemProps {
  product: IProductItem;
  horizontal?: boolean;
  className?: string;
  bg?: string;
  item_list_name?: string;
  cart?: boolean;
  algoliaQueryId: string;
  index: number;
  setShowResults?: any;
  filter: FilterData;
}

export default function VerticalItem({
  className,
  product,
  bg = 'bg-white',
  item_list_name,
  cart = false,
  algoliaQueryId,
  index,
  setShowResults,
  filter,
}: IProductItemProps) {
  const algolia = useAlgolia();
  const isRtl = useRtl();
  const { cartData, addCartItems } = useCart();
  const { t } = useTranslation('common');
  const { locale, push, pathname } = useRouter();
  const { customerData, isGuest } = useCustomer();
  const hasImage = product?.media?.image ? product?.media?.image[0]?.original : product?.image;
  const productNameLoacalSelector = locale?.split('-')[0] || 'en';
  const prodName = typeof product?.name === 'object' ? product?.name[productNameLoacalSelector] : product?.name;
  const [, setAlgoliaProductQueryIds] = useLocalStorage<any>('algoliaProductQueryIds', {});

  const prescriptionStatus = product?.attributes?.rx === true || product?.attributes?.rx?.value === true;

  const plp = product?.labels?.plp || [];

  const findOffer = (position: string) => {
    return plp?.filter((item: any) => item?.position === position);
  };
  const above_OfferTags = plp && plp?.length > 0 ? findOffer('above_image') : [];
  const below_OfferTags = plp && plp?.length > 0 ? findOffer('below_image') : [];
  const top_OfferTags = plp && plp?.length > 0 ? findOffer('top') : [];
  const top_left_OfferTags = plp && plp?.length > 0 ? findOffer('top_left') : [];
  const top_right_OfferTags = plp && plp?.length > 0 ? findOffer('top_right') : [];
  const bottom_OfferTags = plp && plp?.length > 0 ? findOffer('bottom') : [];
  const bottom_left_OfferTags = plp && plp?.length > 0 ? findOffer('bottom_left') : [];
  const bottom_right_OfferTags = plp && plp?.length > 0 ? findOffer('bottom_right') : [];
  const center_OfferTags = plp && plp?.length > 0 ? findOffer('center') : [];
  const center_left_OfferTags = plp && plp?.length > 0 ? findOffer('center_left') : [];
  const center_right_OfferTags = plp && plp?.length > 0 ? findOffer('center_right') : [];
  const [inputText] = useSessionStorage<any>('searchInputState', '');

  const isProductInCart = cartData?.items?.find((item: any) => item.sku == product?.sku);
  const productBrand = product?.attributes?.brand?.[isRtl ? 'ar' : 'en'] ?? product?.attributes?.brand?.value?.label;
  const user_id: string = customerData?.id ? String(customerData?.id) : 'guest';
  const { isInWishlist, addWishlist, deleteWishlist } = useWishlist();
  const isInWishList = isInWishlist(product?.entity_id);
  const isMobile = useContext(DeviceDetector).isMobile;

  return (
    <div className={`max-w-[170px] p-2 flex flex-col m-auto justify-between h-[280px] ${bg} rounded ${className}`}>
      {
        <>
          <div
            className={`relative rounded-lg flex justify-center  border-[#ececec] border-[1px] max-w-[156px] min-w-[156px] w-[154px] h-[120px] ${
              !hasImage && 'bg-dark-white'
            }`}
          >
            <Link
              className={`${cart ? 'pointer-events-none' : ''}`}
              href={
                cart
                  ? '#'
                  : productUrlGeneratorWithUrlKey(
                      typeof product?.url_key === 'object' ? product.url_key[isRtl ? 'ar' : 'en'] : product?.url_key,
                    )
              }
            >
              <a
                className='block cursor-pointer'
                onClick={() => {
                  selectContentEvent([product], item_list_name);
                  setShowResults && setShowResults(false);
                  if (algoliaQueryId) {
                    algolia.sendEvents(
                      inputText ? AlgoliaEventTypes.clickedObjectIDsAfterSearch : AlgoliaEventTypes.clickedObjects,
                      {
                        userToken: user_id,
                        eventName: AlgoliaEventNames.clickedObjectIDsAfterSearch,
                        index: algolia.productIndexName,
                        queryID: inputText ? algoliaQueryId : undefined,
                        objectIDs: [String(product?.entity_id)],
                        positions: inputText ? [index] : undefined,
                      },
                    );
                  } else {
                    algolia.sendEvents(AlgoliaEventTypes.clickedObjects, {
                      userToken: user_id,
                      eventName: AlgoliaEventNames.clickedObjectIDsAfterSearch,
                      index: algolia.productIndexName,
                      objectIDs: [String(product?.entity_id)],
                    });
                  }
                }}
              >
                <PharmacyImage
                  imageWidth={150}
                  imageHeight={120}
                  src={hasImage || '/local/image/placeholder_new.png'}
                  objectFit='contain'
                  localImage={hasImage ? false : true}
                  alt={prodName}
                />
              </a>
            </Link>
            <CustomBadges OfferTags={above_OfferTags} t={t} />
            <CustomBadges OfferTags={below_OfferTags} t={t} />
            <CustomBadges OfferTags={top_OfferTags} t={t} />
            <CustomBadges OfferTags={top_left_OfferTags} t={t} />
            <CustomBadges OfferTags={top_right_OfferTags} t={t} />
            <CustomBadges OfferTags={bottom_OfferTags} t={t} />
            <CustomBadges OfferTags={bottom_left_OfferTags} t={t} />
            <CustomBadges OfferTags={bottom_right_OfferTags} t={t} />
            <CustomBadges OfferTags={center_OfferTags} t={t} />
            <CustomBadges OfferTags={center_left_OfferTags} t={t} />
            <CustomBadges OfferTags={center_right_OfferTags} t={t} />
            <div className='absolute right-0 w-fit p-0 m-0 max-w-fit rounded-[15px]'>
              <Button
                aria-label='add to wishlist'
                title={''}
                variant='no-border'
                className='font-bold p-0 m-0'
                icon={
                  isInWishList ? (
                    <AddToWishlistRoundedSelectedIcon width={isMobile ? '25' : '34'} height={isMobile ? '25' : '34'} />
                  ) : (
                    <AddToWishlistRoundedIcon width={isMobile ? '25' : '34'} height={isMobile ? '25' : '34'} />
                  )
                }
                onClick={() => {
                  if (isGuest) {
                    push('/login-with-number');
                  } else {
                    if (isInWishList) deleteWishlist(isInWishList?.id);
                    else addWishlist(product.entity_id);
                  }
                }}
              />
            </div>
          </div>

          <p
            onClick={() => {
              setShowResults && setShowResults(false);
              push(`/category/keyword=${productBrand}?filter=brand=${productBrand}`).then(() => {
                if (pathname.includes('category')) window.location.reload();
              });
            }}
            className='group cursor-pointer h-[20px] flex overflow-hidden mt-[4px] text-start rtl:text-right text-apollo-secondary leading-[18px] font-Montserrat font-[600] text-[13px]'
          >
            <span>{truncateText(productBrand || '', 13)}</span>
          </p>
          <div>
            <Link
              className={`${cart ? 'pointer-events-none' : ''}`}
              href={
                cart
                  ? '#'
                  : productUrlGeneratorWithUrlKey(
                      typeof product?.url_key === 'object' ? product.url_key[isRtl ? 'ar' : 'en'] : product?.url_key,
                    )
              }
            >
              <a
                className='block cursor-pointer'
                onClick={() => {
                  selectContentEvent([product], item_list_name);
                  setShowResults && setShowResults(false);
                  if (algoliaQueryId) {
                    algolia.sendEvents(
                      inputText ? AlgoliaEventTypes.clickedObjectIDsAfterSearch : AlgoliaEventTypes.clickedObjects,
                      {
                        userToken: user_id,
                        eventName: AlgoliaEventNames.clickedObjectIDsAfterSearch,
                        index: algolia.productIndexName,
                        queryID: inputText ? algoliaQueryId : undefined,
                        objectIDs: [String(product?.entity_id)],
                        positions: inputText ? [index] : undefined,
                      },
                    );
                  } else {
                    algolia.sendEvents(AlgoliaEventTypes.clickedObjects, {
                      userToken: user_id,
                      eventName: AlgoliaEventNames.clickedObjectIDsAfterSearch,
                      index: algolia.productIndexName,
                      objectIDs: [String(product?.entity_id)],
                    });
                  }
                }}
              >
                <p
                  className={`group flex ${
                    product?.original_price?.with_tax_rounded ? 'h-[19px]' : 'h-[35px]'
                  } overflow-hidden mt-[4px] text-start rtl:text-right text-gray-900 font-[600] font-Montserrat leading-[18px] text-[13px]`}
                >
                  <span className=''>
                    {truncateText(prodName || '', product?.original_price?.with_tax_rounded ? 20 : 35)}
                  </span>
                </p>
                <div
                  className={`flex ${product?.original_price?.with_tax_rounded ? 'mt-[10px]' : 'mt-[13px]'} items-center`}
                >
                  {product?.original_price?.with_tax_rounded && (
                    <ProductItemDiscountRibbon
                      percent={1 - product?.price?.with_tax_rounded / product?.original_price?.with_tax_rounded}
                    />
                  )}
                  <div className='flex flex-col justify-start rtl:justify-end'>
                    {product?.price?.with_tax_rounded && (
                      <p
                        style={{
                          fontSize: product?.original_price?.with_tax_rounded ? '14px' : '17px',
                        }}
                        className={`text-headline font-bold text-dark-gray`}
                      >
                        {product?.price?.with_tax_rounded.toFixed(2)} <span className='rtl:text-[9px]'>{t('SAR')}</span>
                      </p>
                    )}
                    {product?.original_price?.with_tax_rounded && (
                      <div className='flex'>
                        <span className='block font-bold line-through text-caption-1 text-light-gray'>
                          {product?.original_price?.with_tax_rounded} {t('SAR')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </a>
            </Link>
          </div>

          {product?.status === 'prescription' && (
            <div className='flex items-center'>
              <div className='rounded bg-[#0396E8] p-1 w-8 text-caption-2 text-white text-center ml-2'>
                <PrescriptionIconTwo />
              </div>
            </div>
          )}

          <div className={`block ${product?.status == 'in-stock' && !prescriptionStatus ? '' : 'mb-[30px]'}`}>
            {(product?.status === 'disabled' || product?.status === 'out-of-stock') && !prescriptionStatus && (
              <p className='font-bold text-left rtl:text-right text-caption-1 text-warning'>{t('out_of_stock')}</p>
            )}
            {product?.status === 'prescription' && (
              <Button
                title={t('see_prescription') || ''}
                icon={<PrescriptionIconTwo />}
                fullWidth
                className='mt-2.5 bg-[#0396E8] border-[#0396E8]'
                size='small'
              />
            )}
            {!prescriptionStatus &&
              product?.status == 'in-stock' &&
              (isProductInCart ? (
                <div className='flex items-center justify-center'>
                  <QuantityButtons product={{ ...isProductInCart, ...isProductInCart?.product }} isVertical />
                </div>
              ) : (
                <Button
                  title={t('add_to_cart') || ''}
                  icon={<AddCartIcon />}
                  fullWidth
                  className='mt-2.5 font-bold'
                  size='small'
                  onClick={() => {
                    addCartItems(product?.sku, 1).then(async () => {
                      await addToCartEvent(product);

                      if (algoliaQueryId) {
                        algolia.sendEvents(AlgoliaEventTypes.addedToCartObjectIDsAfterSearch, {
                          userToken: user_id,
                          eventName: AlgoliaEventNames.addedToCartObjectIDsAfterSearch,
                          index: algolia.productIndexName,
                          queryID: algoliaQueryId,
                          objectIDs: [`${product?.sku}`],
                          value: product?.price?.with_tax_rounded ?? product?.price?.with_tax_rounded ?? product?.price,
                          currency: 'SAR',
                        });

                        if (filter && Object.keys(filter).length > 0) {
                          Object.keys(filter).map((item) => {
                            algolia.sendEvents(AlgoliaEventTypes.convertedFilters, {
                              userToken: user_id,
                              eventName: `${item} ${AlgoliaEventNames.convertedFilters}`,
                              index: algolia.productIndexName,
                              filters: generateConvertedFiltersArray(filter, isRtl)?.[item],
                            });
                          });
                        }
                        setAlgoliaProductQueryIds((prev) => ({ ...prev, [`${product?.sku}`]: algoliaQueryId }));
                      } else {
                        algolia.sendEvents(AlgoliaEventTypes.addedToCartObjectIDs, {
                          userToken: user_id,
                          eventName: AlgoliaEventNames.addedToCartObjectIDsAfterSearch,
                          index: algolia.productIndexName,
                          objectIDs: [`${product?.sku}`],
                          value: product?.price?.with_tax_rounded ?? product?.price?.with_tax_rounded ?? product?.price,
                          currency: 'SAR',
                        });
                      }
                    });
                  }}
                />
              ))}
            {prescriptionStatus && (
              <p className='text-left rtl:text-right text-warning font-bold text-caption-1 mt-[1px]'>
                {t('prescription_needed')}
              </p>
            )}
          </div>
        </>
      }
    </div>
  );
}
