declare global {
  interface Window {
    Insider: {
      track: (event: string, data: any) => void;
    };
  }
}

interface IinsiderEvent {
  eventName: string;
  data: any;
}

export const insiderPush = ({ eventName, data }: IinsiderEvent) => {
  if (window?.Insider) {
    window.Insider.track('events', [
      {
        event_name: eventName,
        timestamp: new Date().toISOString().replace(/\.\d{3}Z$/, 'Z'),
        event_params: data,
      },
    ]);
  }
};
