import useCart from 'hooks/useCart';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const GetCart = ({ isCat }) => {
  const { pathname } = useRouter();
  const { getCartData } = useCart();
  useEffect(() => {
    !isCat && pathname != '/checkout/verify-order' && getCartData();
  }, []);
  return <></>;
};

export default GetCart;
