import useCart from 'hooks/useCart';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { addToCartEvent, removeFromCartEvent, selectContentEvent } from 'lib/helpers/gtm/events';
import { IProductItem } from 'lib/interface';
import QuantityButtons from 'components/Product/QuantityButtons';
import QuantityButtonsForRefund from './QuantityButtonsForRefund';
import { productUrlGeneratorWithUrlKey } from 'utils/product-url';
import { AddCartIcon } from 'components/SvgIcons/AddCartIcon';
import PharmacyImage from 'components/PharmacyImage';
import { DeviceDetector } from 'utils/dd';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import useRtl from 'hooks/useRtl';
interface IProductItemProps {
  product: IProductItem;
  horizontal?: boolean;
  className?: string;
  bg?: string;
  cartAble?: boolean;
  refundable?: any;
  refundQty?: any;
  item_list_name?: string;
  cart?: boolean;
  is_prescription?: any;
}

export default function HorizontalItem({
  className,
  product,
  bg = 'bg-white',
  cartAble = true,
  refundable = null,
  refundQty = null,
  item_list_name,
  is_prescription = false,
  cart = false,
}: IProductItemProps) {
  const { cartData, addCartItems, deleteCartItems } = useCart();
  const { t } = useTranslation('common');
  const hasImage = product?.media?.image && product?.media?.image[0]?.original;
  const { locale } = useRouter();
  const isRtl = useRtl();
  const productNameLoacalSelector = locale?.split('-')[0] || 'en';
  const prodName = typeof product?.name === 'object' ? product?.name[productNameLoacalSelector] : product?.name;

  const isBot = useContext(DeviceDetector).isBot;

  return (
    <div className={`px-2 py-1  rounded-r flex gap-2 lg:mb-2 ${className}`}>
      {cart ? (
        <a className='rounded-lg w-[86px] h-auto pt-1 border-[#ececec] border-[1px]'>
          <PharmacyImage
            className='rounded-lg border-[#ececec] border-[1px]'
            imageWidth={150}
            imageHeight={150}
            objectFit={hasImage ? 'contain' : 'cover'}
            src={hasImage ?? '/local/image/placeholder_new.png'}
            localImage={hasImage ? false : true}
            alt={prodName}
          />
        </a>
      ) : (
        <Link
          href={productUrlGeneratorWithUrlKey(
            typeof product?.url_key === 'object' ? product?.url_key[isRtl ? 'ar' : 'en'] : product?.url_key,
          )}
          prefetch={!isBot}
        >
          {/* eslint-disalbe-next-line */}
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <a
            className='rounded-lg w-[86px] h-[86px] cursor-pointer pt-1 border-[#ececec] border-[1px]'
            onClick={() => selectContentEvent([product], item_list_name)}
          >
            <PharmacyImage
              className='rounded-lg border-[#ececec] border-[1px]'
              imageWidth={150}
              imageHeight={150}
              objectFit={hasImage ? 'contain' : 'cover'}
              src={hasImage ?? '/local/image/placeholder_new.png'}
              localImage={hasImage ? false : true}
              alt={prodName}
            />
          </a>
        </Link>
      )}
      <div className='w-full flex flex-col justify-between'>
        <p
          className={`group h-[20px] flex overflow-hidden mt-[4px] text-start rtl:text-right text-apollo-secondary font-[600] text-[13px]`}
        >
          <span className=''>{truncateText(product?.attributes?.brand?.value?.label || '', 13)}</span>
        </p>
        {cart ? (
          <a className={`group flex text-footnote mb-2.5 text-gray-900`}>
            {/* {truncateText(product?.name, 28)} */}
            <span className=''>{truncateText(prodName, 28)}</span>
          </a>
        ) : (
          <Link
            href={productUrlGeneratorWithUrlKey(
              typeof product?.url_key === 'object' ? product.url_key[isRtl ? 'ar' : 'en'] : product?.url_key,
            )}
          >
            <a className={`group flex text-footnote mb-2.5 text-gray-900`}>
              <span className=''>{truncateText(prodName, 28)}</span>
            </a>
          </Link>
        )}
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <div>
              {product?.base_price_incl_tax && (
                <h5 className='text-headline text-[15px] font-bold text-dark-gray'>
                  {is_prescription ? product?.patient_share : product?.base_price_incl_tax?.toFixed(2)} {t('SAR')}
                </h5>
              )}
              {product?.original_price?.with_tax_rounded && (
                <p className='text-caption-1 line-through text-light-gray'>
                  {product?.original_price?.with_tax_rounded?.toFixed(2)} {t('SAR')}
                </p>
              )}
            </div>
          </div>
          {!product?.attributes?.rx?.value && cartAble && product?.status === 'in-stock' && (
            <button
              className={`inline-flex border-2 items-center apollo-button rounded-lg disabled:opacity-40 disabled:pointer-events-none justify-center ${
                cartData?.items?.find((item: any) => item.sku == product.sku)
                  ? 'border-error bg-error'
                  : 'border-primary bg-primary hover:border-secondary hover:bg-secondary'
              } w-10 h-10 icon-buttom`}
              onClick={() => {
                const id = cartData?.items?.find((item: any) => item.sku == product.sku);
                if (id) {
                  deleteCartItems(id.item_id);
                  removeFromCartEvent(product);
                } else {
                  addCartItems(product.sku, 1).then(async () => await addToCartEvent(product));
                }
              }}
            >
              <AddCartIcon />
            </button>
          )}
          {!refundable && !is_prescription && <QuantityButtons product={product} />}
          {is_prescription && (
            <p>
              {t('quantity')}: {product?.qty}
            </p>
          )}
          {refundable && <QuantityButtonsForRefund refundable={refundable} />}
          {refundQty && (
            <span className='inline-flex items-center rounded-lg justify-center bg-none'>
              {refundQty} {t('qty')}
            </span>
          )}
        </div>
        {product?.attributes?.rx?.value && (
          <h5 className='text-left rtl:text-right text-warning font-bold text-caption-1 mt-[1px]'>
            {t('prescription_needed')}
          </h5>
        )}
        {(product?.status === 'disabled' || product?.status === 'out-of-stock') && !product.attributes?.rx?.value && (
          <p className='text-caption-1 text-warning font-bold mt-[1px]'>{t('out_of_stock')}</p>
        )}
      </div>
    </div>
  );
}
