import React, { useEffect } from 'react';
import Link from 'next/link';
import useGetLakumHistory from 'queries/useLakum';
import useLakumInfo from 'hooks/useLakumInfo';
import useSessionStorage from 'hooks/useSessionStorage';
import { useLoading } from 'hooks/useLoading';
import { LoaderIcon } from 'components/SvgIcons/LoaderIcon';

export const LakumPoints = ({ t, push, pathname, isMobile }) => {
  const { data: lakumHistory, isLoading, isFetching } = useGetLakumHistory();
  const { getLakumInfo, lakumInfo } = useLakumInfo();
  const [, setLoading] = useLoading();
  const [, setLakumModal] = useSessionStorage('lakumModal', false);
  const loading = isLoading && isFetching;
  useEffect(() => {
    getLakumInfo().finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className='mb-2'>
        <LoaderIcon width='70' height='70' />
      </div>
    );

  return (
    <div className='text-right rtl:text-left'>
      {!lakumInfo?.account_number || !lakumInfo?.is_active || !lakumHistory ? (
        // <Link href={'/profile/lakum'} prefetch={false}>
        <button
          type='button'
          style={{ borderRadius: '8px' }}
          onClick={() => {
            if (isMobile) {
              push('/profile/lakum');
            } else if (pathname !== '/profile/lakum') {
              setLakumModal(true);
              push('/profile/lakum');
            } else {
              setLakumModal(true);
            }
          }}
          className='items-right text-sm px-3 py-2 text-right mb-2 bg-apollo-linear-gradient-1 text-white font-medium leading-tight rounded transition duration-150 ease-in-out'
        >
          {t('activate_lakum')}
        </button>
      ) : loading ? (
        <div className='mb-2'>
          <LoaderIcon width='70' height='70' />
        </div>
      ) : (
        <Link href={'/profile/lakum'} prefetch={false}>
          <button
            type='button'
            style={{ borderRadius: '8px' }}
            className='items-right text-sm px-3 py-1 text-right mb-2 bg-apollo-linear-gradient-1 text-white font-medium leading-tight rounded transition duration-150 ease-in-out'
          >
            <div className='text-[14px]'>
              <span className='font-bold'>{lakumHistory?.points_balance ?? 0}</span> {t('points')}
            </div>
            <div className='text-[12px]'>
              {t('SAR')} {lakumHistory?.points_balance_amount ?? 0}
            </div>
          </button>
        </Link>
      )}
    </div>
  );
};
