import useStoreCreditQuery from 'queries/useStoreCreditQuery';
import { useRouter } from 'next/router';

export default function useStoreCredit() {
  const { pathname } = useRouter();
  const isEnabled = [
    '/profile',
    '/profile/lakum',
    '/profile/orders',
    '/profile/prescriptions',
    '/profile/wishlist',
    '/profile/alarms',
    '/profile/refunds',
    '/profile/profile-settings/addresses',
    '/profile/profile-settings/payments',
    '/profile/profile-settings/account-information',
    '/profile/profile-settings/allergies-and-conditions',
    '/checkout/payment-method',
  ].includes(pathname);
  const { data: storeCredit } = useStoreCreditQuery({}, isEnabled);

  return {
    storeCredit,
  };
}
