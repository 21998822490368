import { event } from 'components/GoogleTagManager';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import MobileNavs from './MobileNav';
import { useContext } from 'react';
import { DeviceDetector } from 'utils/dd';
import Link from 'next/link';

const NavMenuLinks = dynamic(() => import('./NavMenuLinks'), { ssr: true });

const Navigation = () => {
  const { t } = useTranslation();
  const { pathname, push } = useRouter();

  const Device = useContext(DeviceDetector);

  return (
    <>
      {Device.isMobile ? (
        <MobileNavs t={t} />
      ) : (
        <div className='hidden md:block lg:block justify-center pt-[1px] bg-apollo-linear-gradient h-[48px] md:mb-5 text-[14px] text-white border-t-2 border-grey items-center '>
          <nav className='bg-transparent w-[80%] m-auto border-gray-200'>
            <div className='hidden w-full md:block md:w-auto'>
              <ul className='flex w-full mt-4 bg-transparent md:flex-row md:space-x-8 md:mt-0 md:text-sm text-callout'>
                <li className='menu-link cat-btn'>
                  <button
                    onClick={() => {
                      event('main_navigation', { tab_selected: 'all-categories' });
                    }}
                    className='py-[5px] px-[8px] cursor-pointer flex justify-between items-center py-2 pr-4 pl-3 w-full text-callout text-gray-700 border-b border-gray-100 md:border-0 hover:bg-apollo-white md:hover:text-blue-700 hover:text-black md:p-0 md:w-auto'
                  >
                    {t('categories')}
                  </button>
                  <ul
                    style={{ zIndex: 999 }}
                    className='bg-white cat-links z-10 w-52 font-bold text-callout bg-white divide-y divide-gray-100 absolute translate-y-[12px] py-1 text-sm text-apollo-black'
                  >
                    <NavMenuLinks />
                  </ul>
                </li>
                <li className='menu-link'>
                  <Link href='/profile/prescriptions' legacyBehavior prefetch={false}>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        event('main_navigation', { tab_selected: 'prescriptions' });
                        push('/profile/prescriptions');
                      }}
                      className={`${
                        pathname.includes('profile/prescriptions') ? 'apollo-active' : ''
                      } py-[5px] nav-link px-[8px] cursor-pointer block text-gray-700 hover:apollo-active md:border-0`}
                    >
                      {t('prescriptions')}
                    </span>
                  </Link>
                </li>
                <li className={`menu-link`}>
                  <Link href='/profile/lakum' legacyBehavior prefetch={false}>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        event('main_navigation', { tab_selected: 'lakum' });
                        push('/profile/lakum');
                      }}
                      className={`${
                        pathname.includes('profile/lakum') ? 'apollo-active' : ''
                      } py-[5px] nav-link px-[8px] cursor-pointer block text-gray-700 hover:bg-apollo-white md:border-0`}
                    >
                      {t('lakum')}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default Navigation;
