import { SVG } from 'lib/interface';

export const NotificationIcon = ({ color = '#595959' }: SVG) => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.5 22.5C13.1193 22.5008 13.7235 22.3086 14.2285 21.9502C14.7335 21.5917 15.1143 21.0849 15.318 20.5H9.682C9.88566 21.0849 10.2665 21.5917 10.7715 21.9502C11.2765 22.3086 11.8807 22.5008 12.5 22.5V22.5ZM19.5 15.086V10.5C19.5 7.283 17.315 4.573 14.355 3.758C14.062 3.02 13.346 2.5 12.5 2.5C11.654 2.5 10.938 3.02 10.645 3.758C7.685 4.574 5.5 7.283 5.5 10.5V15.086L3.793 16.793C3.69996 16.8857 3.62617 16.9959 3.57589 17.1172C3.5256 17.2386 3.49981 17.3687 3.5 17.5V18.5C3.5 18.7652 3.60536 19.0196 3.79289 19.2071C3.98043 19.3946 4.23478 19.5 4.5 19.5H20.5C20.7652 19.5 21.0196 19.3946 21.2071 19.2071C21.3946 19.0196 21.5 18.7652 21.5 18.5V17.5C21.5002 17.3687 21.4744 17.2386 21.4241 17.1172C21.3738 16.9959 21.3 16.8857 21.207 16.793L19.5 15.086Z'
      fill={color}
    />
  </svg>
);
