import React from 'react';
import { useRouter } from 'next/router';
import useCart from 'hooks/useCart';
import { CartIcon } from 'components/SvgIcons/CartIcon';

export default function Cart() {
  const { cartData } = useCart();
  const { push } = useRouter();
  return (
    <button
      type='button'
      onClick={() => push('/checkout')}
      aria-label='cart'
      className='inline-flex items-center text-sm px-3 py-1 text-center mx-2 mb-2 bg-apollo-primary text-white font-medium leading-tight rounded transition duration-150 ease-in-out'
    >
      <CartIcon />
      <div className='text-[12px] mx-1'>{cartData?.items_qty !== 0 ? cartData?.items_qty : ''}</div>
    </button>
  );
}
