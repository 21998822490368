import { add_prescrepted_wishlist, add_wishlist, delete_wishlist } from 'models/wishlist';
import { errorToast, successToast } from 'lib/taost';
import useSessionStorage from 'hooks/useSessionStorage';
import { useTranslation } from 'next-i18next';
import useWishlistQuery from 'queries/useWishlistQuery';
import { useCallback } from 'react';
import isArray from 'lodash/isArray';

const useWishlist = () => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useSessionStorage('loading', false);
  const { data: wishlistData, refetch } = useWishlistQuery();

  const addWishlist = useCallback(async (id: any) => {
    setLoading(true);
    try {
      await add_wishlist(id);
      refetch();
      successToast(t('added_to_wishlist'));
    } catch (e) {
      console.log(e);
      errorToast(t('something_went_wrong'));
    } finally {
      setLoading(false);
    }
  }, []);

  const addPrescreptedWishlist = useCallback(async (id: any) => {
    setLoading(true);
    try {
      await add_prescrepted_wishlist(id);
      refetch();
      successToast(t('added_to_wishlist'));
    } catch (e) {
      console.log(e);
      errorToast(t('something_went_wrong'));
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteWishlist = useCallback(async (id: any) => {
    setLoading(true);
    try {
      await delete_wishlist(id);
      refetch();
      successToast(t('removed_from_wishlist'));
    } catch (e) {
      console.log(e);
      errorToast(t('something_went_wrong'));
    } finally {
      setLoading(false);
    }
  }, []);

  const isInWishlist = useCallback(
    (id: any) => {
      return wishlistData && isArray(wishlistData) && wishlistData?.find((item: any) => item.product_id === id);
    },
    [wishlistData],
  );

  const isInPrescreptedWishlist = useCallback(
    (id: any) => {
      return wishlistData && isArray(wishlistData) && wishlistData?.find((item: any) => item?.product?.sku === id);
    },
    [wishlistData],
  );

  return {
    loading,
    wishlistData,
    addWishlist,
    isInPrescreptedWishlist,
    deleteWishlist,
    addPrescreptedWishlist,
    isInWishlist,
  };
};

export default useWishlist;
