import { logout, subscribe, unsubscribe, update_customer, delete_customer } from 'models/customer';
import { errorToast, successToast } from 'lib/taost';
import useCustomerQuery from 'queries/useCustomerQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useLoading } from './useLoading';
import { event } from 'components/GoogleTagManager';
import useCookieStorage from 'hooks/useCookieStorage';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { clearSession, clearLocalStorage } from 'utils/index';
import { apolloErrorToast } from 'utils/apollo-error-toast';
import { LanguageType } from 'lib/constants';
import useRtl from './useRtl';

let isLoggingOut = false;

const useCustomer = () => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useLoading();
  const [customerId, setIsLoggin] = useCookieStorage('isCustomerLoggedIn', false, {});
  const queryClient = useQueryClient();
  const { isLoading, data: customer, refetch } = useCustomerQuery();
  const { pathname } = useRouter();
  const loginWithNumberRoute = '/login-with-number';
  const isRtl = useRtl();

  useEffect(() => {
    localStorage.setItem('user_id', customer?.id ?? '');
  }, [customer?.id]);

  const logoutOnInvalidCustomer = async () => {
    clearSession();
    clearLocalStorage();
    setIsLoggin(false);
    if (!isLoggingOut) {
      isLoggingOut = true;
      await logout();
    }
    window.location.reload();
  };

  useEffect(() => {
    if (customer?.auto_created) {
      if (!['/checkout', '/login', loginWithNumberRoute, '/signup'].includes(pathname)) {
        logoutOnInvalidCustomer();
      }
    }
  }, [customer?.auto_created]);

  const subscribeNewsletter = async () => {
    setLoading(true);
    try {
      await subscribe();
      successToast(t('subscribed'));
      refetch();
    } catch (e) {
      console.log(e);
      errorToast(t('something_went_wrong'));
    } finally {
      setLoading(false);
    }
  };

  const unsubscribeNewsletter = async () => {
    setLoading(true);
    try {
      await unsubscribe();
      successToast(t('unsubscribed'));
      refetch();
    } catch (e) {
      console.log(e);
      errorToast(t('something_went_wrong'));
    } finally {
      setLoading(false);
    }
  };

  const getCustomerData = async (removeQuery?: boolean) => {
    if (removeQuery) return queryClient.removeQueries(['customer', customerId]);
    return queryClient.invalidateQueries(['customer', customerId]);
  };

  const logoutUser = async (push: Function) => {
    setLoading(true);
    try {
      clearSession();
      clearLocalStorage();
      await logout();
      await getCustomerData(true);
      event('logout', {});
      push(loginWithNumberRoute, loginWithNumberRoute, { locale: isRtl ? LanguageType.En : LanguageType.Ar });
      localStorage.removeItem('cart');
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoggin(false);
      setLoading(false);
    }
  };

  const deleteUser = async (push: Function) => {
    setLoading(true);
    try {
      clearSession();
      clearLocalStorage();
      await delete_customer();
      setIsLoggin(false);
      push(loginWithNumberRoute);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAllergensAndConditions = async (
    data: number[],
    customer: any,
    setAddAllergensAndCondition: Function,
    callback?: Function,
  ) => {
    setLoading(true);
    try {
      const resultData = await update_customer({ ...customer, medicalcontent: data });
      setAddAllergensAndCondition(resultData?.medicalcontent?.map((item: any) => item.entity_id));
      refetch();
      callback && callback();
      successToast(t('item_updated_successfully'));
    } catch (err: any) {
      console.log('err', err);
      apolloErrorToast({ message: err?.response?.data?.message, Api: err?.config?.url ?? '' });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading: isLoading || loading,
    isGuest: !customer,
    customer,
    customerData: customer,
    isLakumActive: customer?.lakum_account_status && customer?.lakum_account_status !== 'Hold' ? true : false,
    subscribeNewsletter,
    unsubscribeNewsletter,
    getCustomerData,
    logoutUser,
    handleUpdateAllergensAndConditions,
    refetch,
    deleteUser,
  };
};

export default useCustomer;
