import { Button } from 'components/Button';
import { GuestIcon } from 'components/SvgIcons/GuestIcon';
export const GuestUserButton = ({ push, t }) => {
  return (
    <div className='bg-dark-white p-4'>
      <div className='grid bg-white grid-cols-3 p-4 rounded'>
        <div className='col-start-1 col-span-1'>
          <GuestIcon />
        </div>
        <div className='col-start-2 col-span-2'>
          <div className='font-bold text-[17px] font-primary'>{t('are_you_sure_want_to_be_a_guest')}</div>
          <div className='text-[12px] mt-2 mb-3 font-primary'>{t('if_you_sign_in')}</div>
          <Button onClick={() => push('/login-with-number')} title={t('sign_up') || ''} />
        </div>
      </div>
    </div>
  );
};
