import useSessionStorageState from 'use-session-storage-state';
import { useState } from 'react';

export default function useSessionStorage<T>(keyName: string, initialValue: T, options?: any) {
  let returnState = useState<T | undefined>(initialValue);
  try {
    const [value, setValue] = useSessionStorageState<T>(keyName, {
      defaultValue: initialValue,
      ...options,
    });
    returnState = [value, setValue];
  } catch (e) {
    console.error(e);
  }

  return returnState;
}
