import { SessionStoragekeys, LocalStoragekeys, SessionStoragekeysAfterOrder, LocalKeysToKeep } from 'lib/constants';
import { resetSessionStorage } from 'lib/helpers/functions/ClearSessionStorage';
import { resetLocalStorage } from 'lib/helpers/functions/ClearLocalStorage';
import { del, get, post, put } from 'models/base';
import { CouponCodeItem } from 'src/types/cart/coupon-code-item.interface';
import {
  Cart,
  PaymentMethod,
  ActionCart,
  ShippingInfo,
  RequestShippingInfo,
  RequestAddress,
  ShippingMethod,
  AvailableShippingMethods,
  CartVerify,
  FreeShipping,
  PickupLocation,
  CartPlaceOrder,
} from '../types/cart/cart.type';
import { StoreCredit } from '../types/customer/customer.type';

export enum CartApiUrls {
  Base = '/cart',
  ItemsBatchDelete = '/cart/items/batch-delete',
  Items = '/cart/items',
  PlaceOrder = '/cart/place-order',
  CouponCode = '/cart/coupon-code',
  StoreCredit = '/cart/store-credit',
  Loyalty = '/cart/loyalty',
  LoyaltyDelete = '/cart/loyalty/delete',
  AvailablePaymentMethods = '/cart/available-payment-methods',
  PaymentMethod = '/cart/payment-method',
  CustomerStoreCredits = '/customer/store-credits',
  ShippingInformation = '/cart/shipping-information',
  BillingAddress = '/cart/billing-address',
  EstimateShippingMethods = '/cart/estimate-shipping-methods',
  AvailableShippingMethods = '/cart/available-shipping-methods',
  Payfort = '/cart/payfort',
  PayfortVerify = '/cart/payfort/verify',
  AdditionalInformation = '/cart/additional-information',
  FreeShippingCondition = '/cart/free-shipping-condition',
  PickupLocationInfo = '/cart/pickup-location-info',
  CheckStock = '/cart/check-stock',
  SubInventoryCode = '/cart/sub-inventory-code',
  Note = '/cart/note',
}

export const add_cart_item = async (sku: string, qty: number, req?: any): Promise<Cart> => {
  const { data } = await put(
    CartApiUrls.Base,
    {
      items: [
        {
          sku,
          qty,
        },
      ],
    },
    req,
  );
  return data;
};

export const delete_cart_item = async (id: number | undefined, ids?: any, req?: any): Promise<Cart> => {
  const { data } = await post(CartApiUrls.ItemsBatchDelete, { items: ids ? ids : [id] }, req);
  resetSessionStorage(SessionStoragekeys);
  resetLocalStorage(LocalStoragekeys);
  return data;
};

export const update_cart_item = async (item_id: number, qty: number, sku: string, req?: any): Promise<Cart> => {
  const { data } = await put(CartApiUrls.Items, { item_id, qty, sku }, req);
  return data;
};

export const get_cart = async (req?: any): Promise<Cart> => {
  const { data } = await get(CartApiUrls.Base, req);
  return data;
};

export const place_order = async (paymentMethod: string, options?: any): Promise<CartPlaceOrder> => {
  const { data } = await put(CartApiUrls.PlaceOrder, {
    paymentMethod: {
      method: paymentMethod,
      ...(options && { ...options }),
    },
  });
  resetSessionStorage(SessionStoragekeysAfterOrder);
  resetLocalStorage(LocalKeysToKeep);
  return data;
};

export const coupon_code = async (req?: any): Promise<CouponCodeItem[]> => {
  const { data } = await get(CartApiUrls.CouponCode, req);
  return data;
};

export const apply_coupon_code = async (code: string, req?: any): Promise<ActionCart> => {
  const { data } = await put(CartApiUrls.CouponCode, { coupon_code: code }, req);
  return data;
};

export const remove_coupon_code = async (req?: any): Promise<ActionCart> => {
  const { data } = await del(CartApiUrls.CouponCode, req);
  return data;
};

export const apply_store_credit = async (req?: any): Promise<ActionCart> => {
  const { data } = await put(CartApiUrls.StoreCredit, {}, req);
  return data;
};

export const delete_store_credit = async (req?: any): Promise<ActionCart> => {
  const { data } = await del(CartApiUrls.StoreCredit, {}, req);
  return data;
};

export const apply_lukam_points = async (amount: number, req?: any): Promise<ActionCart> => {
  const { data } = await put(
    CartApiUrls.Loyalty,
    {
      program: 'LAKUM',
      amount,
    },
    req,
  );
  return data;
};

export const remove_lukam_points = async (req?: any): Promise<ActionCart> => {
  const { data } = await post(CartApiUrls.LoyaltyDelete, {}, req);
  return data;
};

export const get_payment_methods = async (req?: any): Promise<PaymentMethod[]> => {
  const { data } = await get(CartApiUrls.AvailablePaymentMethods, req);
  return data;
};

export const update_payment_methods = async (payload: any, req?: any): Promise<ActionCart> => {
  const { data } = await put(CartApiUrls.PaymentMethod, payload, req);
  return data;
};

export const get_store_credit = async (req?: any): Promise<StoreCredit> => {
  const { data } = await get(CartApiUrls.CustomerStoreCredits, req);
  return data;
};

export const remove_store_credit = async (req?: any): Promise<ActionCart> => {
  const { data } = await del(CartApiUrls.StoreCredit, req);
  return data;
};

export const shipping_information = async (address: RequestShippingInfo, req?: any): Promise<ShippingInfo> => {
  const { data } = await post(CartApiUrls.ShippingInformation, address, req);
  return data;
};

export const billing_address = async (address: RequestAddress, req?: any): Promise<number> => {
  const { data } = await post(CartApiUrls.BillingAddress, address, req);
  return data;
};

export const estimate_shipping_methods = async (req?: any): Promise<ShippingMethod> => {
  const { data } = await get(CartApiUrls.EstimateShippingMethods, req);
  return data;
};

export const available_shipping_methods = async (
  payload: { latitude: string; longitude: string; city: string; country_id: string },
  req?: any,
): Promise<AvailableShippingMethods> => {
  const { data } = await post(CartApiUrls.AvailableShippingMethods, payload, req);
  return data;
};

export const payfort = async (url: string) => {
  const { data } = await post(url, {}, {}, true);
  return data;
};

export const payfort_validate = async (targetUrl: string): Promise<CartVerify> => {
  const { data } = await post(CartApiUrls.PayfortVerify, {
    verify: targetUrl,
  });
  return data;
};

export const additional_info = async ({
  latitude,
  longitude,
}: {
  latitude: string;
  longitude: string;
}): Promise<void> => {
  await put(CartApiUrls.AdditionalInformation, { latitude, longitude }, {});
};

export const free_shipment = async (req?: any): Promise<FreeShipping> => {
  const { data } = await get(CartApiUrls.FreeShippingCondition, req);
  return data;
};

export const pick_location_info = async (
  payload: { latitude: string; longitude: string; city: string; sub_inventory_code: string },
  req?: any,
): Promise<PickupLocation> => {
  const { data } = await get(
    `${CartApiUrls.PickupLocationInfo}?latitude=${payload.latitude}&longitude=${payload.longitude}&city=${payload.city}&sub_inventory_code=${payload.sub_inventory_code}`,
    req,
  );
  return data;
};

export const check_cart_stock = async (req?: any): Promise<ActionCart> => {
  const { data } = await get(CartApiUrls.CheckStock, req);
  return data;
};

export const get_cart_sub_inventory_code = async (req?: any): Promise<ActionCart> => {
  const { data } = await get(CartApiUrls.SubInventoryCode, req);
  return data;
};

export const add_a_note = async (note: string, req?: any): Promise<ActionCart> => {
  const { data } = await post(CartApiUrls.Note, { note }, req);
  return data;
};
