import { MinusCartItemIcon } from 'components/SvgIcons/MinusCartItemIcon';
import { PlusCartItemIcon } from 'components/SvgIcons/PlusCartItemIcon';
import { useEffect } from 'react';

export default function QuantityButtonsForRefund({ refundable: { quantity, onClick, sku, items, disable } }: any) {
  useEffect(() => {}, []);
  const item = items?.find((item: any) => item?.sku === sku);
  return (
    <span className='inline-flex items-center rounded-lg justify-center bg-none'>
      <span
        className={`${disable ? '' : item?.quantity === 0 ? '' : 'cursor-pointer'}`}
        onClick={() => {
          if (!disable) {
            item?.quantity > 0 && onClick(item?.quantity - 1);
          }
        }}
      >
        <MinusCartItemIcon color={disable ? '#757575' : item?.quantity === 0 ? '#757575' : '#ECECEC'} />
      </span>
      <span className='pl-3 px-3 text-[20px]'>{item?.quantity}</span>
      <span
        className={disable ? '' : item?.quantity < quantity ? 'cursor-pointer' : ''}
        onClick={() => {
          if (!disable) {
            item?.quantity < quantity && onClick(item?.quantity + 1);
          }
        }}
      >
        <PlusCartItemIcon color={disable ? '#757575' : item?.quantity < quantity ? '#ECECEC' : '#757575'} />
      </span>
    </span>
  );
}
