import { get_lakum_info, register_lakum, activate_lakum, deactivate_lakum } from 'models/lakum';
import useSessionStorage from 'hooks/useSessionStorage';
import { useTranslation } from 'next-i18next';
import { useLoading } from './useLoading';
import { toast } from 'react-toastify';
import { apolloErrorToast } from 'utils/apollo-error-toast';
import useLakumInfoQuery from 'queries/useLakumInfo';

const useLakumInfo = () => {
  const { t } = useTranslation('common');
  const [, setLoading] = useLoading();
  const [lakumInfo, setLakumInfo] = useSessionStorage('lakumInfo', {});
  // const { data } = useLakumInfoQuery();

  const getLakumInfo = async (cb?: Function) => {
    setLoading(true);
    try {
      const res = await get_lakum_info();
      setLakumInfo(res);
    } catch (error) {
      setLakumInfo({});
      console.log('error', error);
    } finally {
      setLoading(false);
      cb && cb();
    }
  };

  const handleLakumRegister = async (termsAgreed: boolean) => {
    setLoading(true);
    if (termsAgreed) {
      try {
        await register_lakum();
        await activate_lakum();
        getLakumInfo();
        toast.success(t('lakum_register_success'));
      } catch (error) {
        toast.error(t('you_need_to_login_with_hmg'));
        console.log('error when lakum registering:', error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(t('accept_terms_before_submit'));
    }
  };

  const handleLakumActivate = async (termsAgreed: boolean) => {
    setLoading(true);
    if (termsAgreed) {
      try {
        await activate_lakum();
        getLakumInfo();
        toast.success(t('lakum_register_success'));
      } catch (error) {
        toast.error(t('you_need_to_login_with_hmg'));
        console.log('error when lakum registering:', error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(t('accept_terms_before_submit'));
    }
  };

  const handleDeactivateLakum = async (toggle: boolean, callback: Function, refetch: Function) => {
    setLoading(true);
    try {
      await deactivate_lakum();
      getLakumInfo(refetch);
      toast.success(t('lakum_deactivated'));
    } catch (error: any) {
      callback(toggle);
      apolloErrorToast({ message: error?.response?.data?.message, Api: error?.config?.url ?? '' });
      console.log('error when lakum registering:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    // lakumInfo: data,
    lakumInfo,
    // isLakum: data?.account_number ? true : false,
    // isLakumActive: data?.is_active,
    isLakum: lakumInfo?.account_number ? true : false,
    isLakumActive: lakumInfo?.is_active,
    getLakumInfo,
    handleLakumRegister,
    handleDeactivateLakum,
    handleLakumActivate,
  };
};

export default useLakumInfo;
