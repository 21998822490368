import { SVG } from 'lib/interface';
export const AddToWishlistRoundedIcon = ({ width = '30', height = '30' }: SVG) => {
  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='0.251953' width='24' height='24' rx='12' fill='white' fillOpacity='0.2' />
      <rect
        x='0.401153'
        y='0.653106'
        width='23.1977'
        height='23.1977'
        rx='11.5988'
        stroke='#ECECEC'
        strokeOpacity='0.5'
        strokeWidth='0.802305'
      />
      <path
        d='M14.8157 12.6535V14.061H16.1527V14.9993H14.8153L14.8157 16.4067H13.9244L13.924 14.9993H12.5875V14.061H13.9244V12.6535H14.8157ZM15.3697 8.31719C15.8524 8.82535 16.1321 9.50859 16.1517 10.2269C16.1712 10.9452 15.9291 11.6442 15.4749 12.1806L14.842 11.5154C15.4352 10.8004 15.404 9.67913 14.7386 8.9801C14.4165 8.64175 13.9837 8.44584 13.5289 8.43249C13.074 8.41915 12.6315 8.58938 12.292 8.90832L11.697 9.47036L11.1017 8.90879C10.7621 8.58914 10.3191 8.41852 9.86378 8.43195C9.40843 8.44539 8.97525 8.64187 8.65322 8.98104C8.33425 9.31681 8.14806 9.76739 8.13218 10.242C8.1163 10.7165 8.27192 11.1798 8.56766 11.5384L12.3254 15.5008L11.6962 16.1651L7.917 12.1811C7.4621 11.6444 7.21964 10.9447 7.2394 10.2257C7.25916 9.50667 7.53963 8.82291 8.02324 8.31477C8.50686 7.80663 9.15691 7.51266 9.83994 7.49322C10.523 7.47378 11.1872 7.73034 11.6962 8.21023C12.2053 7.73039 12.8698 7.47408 13.5529 7.49397C14.2361 7.51387 14.886 7.80845 15.3692 8.31719H15.3697Z'
        fill='#595959'
        fillOpacity='0.5'
      />
    </svg>
  );
};
