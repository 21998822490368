import moment from 'moment';
import debounce from 'lodash/debounce';
import { LocalKeysToKeep, SessionKeysToKeep } from 'lib/constants';
export const onDebounce = (callback: Function, time: number = 1000) => {
  debounce(() => {
    callback();
  }, time);
};

export const getQueryString = ({ name }: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(name)) {
    return urlParams.get(name);
  }
  return null;
};

export const uniqueArray = (array: any) => Array.from(new Set(array));

export const uniqueArrayByKey = (array: any, key: string) => [
  // @ts-ignore
  ...new Map(array.map((item) => [item[key], item])).values(),
];

export const percent = ({ value, total }: any) => (parseFloat(value) / parseFloat(total)) * 100;

export const flattenArray = (arrays: any) => [].concat(...arrays);

export const hasKeys = (obj: Object) => !!Object.keys(obj).length;

export const capitalize = (str = '') =>
  str.replace(/\S/g, (e) => e.toLowerCase()).replace(/^\S/, (e) => e.toUpperCase());

export const objectToArray = (object: any, keyReplacer?: any) =>
  Object.keys(object).map((e) => (keyReplacer ? { ...object[e], [keyReplacer]: e } : object[e]));

export const objectToKeys = (object: any, value: any) => {
  const obj: any = {};
  Object.keys(object).map((e) => (obj[e] = value));
  return obj;
};

export const objectToArrayUUID = (object: any, keyReplacer: any) =>
  Object.keys(object).map((e) => (keyReplacer ? { [keyReplacer]: e, value: object[e] } : { ...object[e] }));

export const arrayToObject = (array: any, keyReplacer: any) =>
  array?.reduce((t: any, c: any) => {
    const { [keyReplacer]: key, ...props } = c;
    t[key] = props;
    return t;
  }, {});

export const formatNumber = (num: number) => Number(num).toLocaleString();

export const downloadFile = ({ content, type, filename }: any) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = `data:${type},${content}`;

  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const isObjEqual = (sourceObj: any = {}, targetObj: any = {}) => {
  const keys = Object.keys(sourceObj);
  return keys.every((key) => sourceObj[key] === targetObj[key]);
};

export const enumToArray = (enums: any) => Object.keys(enums).map((e) => enums[e]);

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const BufferToBase64 = (buffer: any) => {
  const buff = new Buffer(buffer, 'base64');
  return buff.toString('ascii');
};

export const fileToBase64 = (filename, filepath) => {
  return new Promise((resolve) => {
    const file = new File([filename], filepath, {
      type: 'image/png',
    });
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      // @ts-ignore
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};

export const convertBase64Chunked = (file: any) => {
  return new Promise((resolve, reject) => {
    const CHUNK_SIZE = 1024; // Set your desired chunk size

    const fileReader = new FileReader();
    const chunks = [];
    let currentPosition = 0;

    fileReader.onload = (event) => {
      // @ts-ignore
      const chunk = event.target.result;
      // @ts-ignore
      chunks.push(chunk);

      // @ts-ignore
      currentPosition += chunk.length;
      if (currentPosition < file.size) {
        readNextChunk();
      } else {
        const base64 = chunks.join('');
        resolve(base64);
      }
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    function readNextChunk() {
      const nextChunk = file.slice(currentPosition, currentPosition + CHUNK_SIZE);
      fileReader.readAsDataURL(nextChunk);
    }

    readNextChunk();
  });
};

export const isContainString = (str: any, search: any) => {
  return str?.toLowerCase().includes(search.toLowerCase());
};

export const lokalizeDate = (inputDate: any, isRTL: boolean, format: string = 'MMMM Do, YYYY') => {
  if (!inputDate) {
    return '-';
  }
  if (!isRTL) {
    return moment(new Date((inputDate + ' UTC').replace(/-/g, '/')).toLocaleString('en-US')).format(format);
  }

  return new Date((inputDate + ' UTC').replace(/-/g, '/')).toLocaleString('ar-EG', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

export const lokalizeDateOnly = (inputDate: any, isRTL: boolean, format: string = 'MMMM Do, YYYY') => {
  if (!inputDate) {
    return '-';
  }

  const dateObject = new Date((inputDate + ' UTC').replace(/-/g, '/'));

  if (!isRTL) {
    return moment(dateObject).format(format);
  }

  return dateObject.toLocaleDateString('ar-EG', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const clearSession = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key: string | null = sessionStorage.key(i);

    if (key !== null && !SessionKeysToKeep.includes(key)) {
      sessionStorage.removeItem(key);
    }
  }
};

export const clearLocalStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key: string | null = localStorage.key(i);

    if (key !== null && !LocalKeysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

export const imageToCloudFlare = (
  url: string,
  {
    width,
    height,
    quality = 75,
    format = 'auto',
  }: { width?: number; height?: number; quality?: number; format?: string },
  domain = 'https://alhabibpharmacy.com',
) => {
  width = width ? width * 2 : 500;
  height = height ? height * 2 : 500;
  const replaceURL = url?.split('/media/')[0];
  url = url?.replace(
    replaceURL,
    // @ts-ignore
    process.env.NEXT_PUBLIC_NEXTJS_S3_BUCKET ||
      'https://s3.me-south-1.amazonaws.com/beta-bucket.alhabibpharmacydev.com',
  );
  return `${domain}/cdn-cgi/image/width=${width},height=${height},quality=${quality},format=${format}/${url}`;
};

export const isBrowser = () => typeof window !== 'undefined';

export const truncateLongWords = (inputString: string, maxLength: number) => {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength) + '...';
  } else {
    return inputString;
  }
};
