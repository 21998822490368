export const SearchNotFoundIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'>
    <path
      d='M45.3334 37.3333H43.2267L42.4801 36.6133C45.1837 33.4774 46.6697 29.4739 46.6667 25.3333C46.6667 20.7362 44.8406 16.3274 41.5899 13.0768C38.3393 9.82618 33.9305 8 29.3334 8C20.2134 8 12.7467 15.04 12.0801 24H17.4667C18.1334 18 23.1467 13.3333 29.3334 13.3333C35.9734 13.3333 41.3334 18.6933 41.3334 25.3333C41.3334 31.9733 35.9734 37.3333 29.3334 37.3333C28.8801 37.3333 28.4534 37.2533 28.0001 37.2V42.5867C28.4534 42.64 28.8801 42.6667 29.3334 42.6667C33.6267 42.6667 37.5734 41.0933 40.6134 38.48L41.3334 39.2267V41.3333L54.6667 54.64L58.6401 50.6667L45.3334 37.3333Z'
      fill='#757575'
    />
    <path
      d='M21.2542 28.8535L14.6675 35.4402L8.08083 28.8535L6.1875 30.7468L12.7742 37.3335L6.1875 43.9202L8.08083 45.8135L14.6675 39.2268L21.2542 45.8135L23.1475 43.9202L16.5608 37.3335L23.1475 30.7468L21.2542 28.8535Z'
      fill='#757575'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.2543 27.4395L24.5619 30.747L17.9752 37.3337L24.5619 43.9203L21.2543 47.2279L14.6677 40.6412L8.08098 47.2279L4.77344 43.9203L11.3601 37.3337L4.77344 30.747L8.08098 27.4395L14.6677 34.0261L21.2543 27.4395ZM14.6677 35.4403L8.08098 28.8537L6.18765 30.747L12.7743 37.3337L6.18765 43.9203L8.08098 45.8137L14.6677 39.227L21.2543 45.8137L23.1476 43.9203L16.561 37.3337L23.1476 30.747L21.2543 28.8537L14.6677 35.4403Z'
      fill='#757575'
    />
  </svg>
);
