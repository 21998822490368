import { BackIcon } from 'components/SvgIcons/BackIcon';

export const BackButton = ({ t, back, sideBarBackClassName, backColor, sideBarClassName, title, children }) => {
  return (
    <div id='sidebar' className='w-full bg-[#fff] fixed overflow-x-hidden h-[calc(100vh-57px)] z-[2] overflow-y-auto'>
      <div id='sidebar-back' className={sideBarBackClassName}>
        <button onClick={() => back()} className='rtl:rotate-180'>
          <BackIcon color={backColor} />
        </button>
        <div className='text-center'>{t(title)}</div>
      </div>
      <div className={sideBarClassName}>{children}</div>
    </div>
  );
};
