import { SVG } from 'lib/interface';

export const Chevron = ({ color = '#595959' }: SVG) => (
  <svg
    style={{
      display: 'inline-block',
    }}
    className='rtl:rotate-180'
    width='7'
    height='13'
    viewBox='0 0 7 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.01562 12.5518C1.28125 12.5518 1.50537 12.4604 1.68799 12.2778L6.66016 7.35547C6.89258 7.13135 7.00049 6.89062 7.00049 6.6001C7.00049 6.30957 6.89258 6.06055 6.66846 5.84473L1.68799 0.922363C1.50537 0.739746 1.28125 0.648438 1.01562 0.648438C0.484375 0.648438 0.0527344 1.07178 0.0527344 1.60303C0.0527344 1.86865 0.160645 2.10938 0.351562 2.30859L4.71777 6.6084L0.351562 10.8999C0.160645 11.0908 0.0527344 11.3315 0.0527344 11.5972C0.0527344 12.1284 0.484375 12.5518 1.01562 12.5518Z'
      fill={color}
    />
  </svg>
);
