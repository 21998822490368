import { get, post } from 'models/base';
import { Lakum, LakumBalance, LakumHistory } from '../types/lakum/lakum.type';

export const get_lakum_info = async (req?: any): Promise<Lakum> => {
  const response = await get(`/customer/lakum/info`, req);
  return response.data;
};

export const get_lakum_balance = async (req?: any): Promise<LakumBalance> => {
  const { data } = await get(`/customer/lakum/balance`, req);
  return data;
};

export const get_lakum_history = async (req?: any): Promise<LakumHistory> => {
  const { data } = await get(`/customer/lakum/history`, req);
  return data;
};

export const register_lakum = async (req?: any): Promise<void> => {
  await post('/customer/lakum/register', req);
};

export const activate_lakum = async (req?: any): Promise<void> => {
  await post('/customer/lakum/activate', req);
};

export const deactivate_lakum = async (req?: any): Promise<void> => {
  await post('/customer/lakum/deactivate', req);
};
