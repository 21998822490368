const DetectUserAget = (ctx: any) => {
  const isServer = !!ctx.req;
  const userAgent = isServer ? (ctx.req.headers['user-agent'] ?? navigator.userAgent) : false;

  const isMobile = /(iPad|iPhone|Android|Mobile)/i.test(userAgent) || false;
  const isBot = /(bot|googlebot|crawler|spider|robot|crawling|lighthouse|moto g power)/i.test(userAgent) || false;

  return { isMobile, isBot };
};

export default DetectUserAget;
