export const CategoriesIcon = () => (
  <svg
    width='14'
    height='14'
    style={{ display: 'inline-block' }}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='3' cy='3' r='3' fill='#2E8F66' />
    <circle cx='3' cy='11' r='3' fill='#2E8F66' />
    <circle cx='11' cy='3' r='3' fill='#2E8F66' />
    <circle cx='11' cy='11' r='3' fill='#2E8F66' />
  </svg>
);
