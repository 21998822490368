export const categoryUrlGenerator = (categoryName: string, entity_id: any) => {
  const formattedCategoryNameName = categoryName
    .replace(/[^\w\s]/gi, '')
    .replaceAll('/', '_')
    .replaceAll(' ', '-')
    .replace(/\\/g, '_')
    .toLowerCase();
  return `/category/${formattedCategoryNameName.slice(0, 25)}-C-${entity_id}`;
};

export const categoryUrlGeneratorWithUrlKey = (categoryUrlKey: string) => {
  return `/category/${categoryUrlKey}`;
};

export const categoryBaseReformate = (formattedCategoryNameName: string) => {
  return formattedCategoryNameName.replace(/\\/g, '_').replace('--', ' & ').replaceAll('-', ' ').toUpperCase();
};
