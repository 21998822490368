export const TrashIcon = ({ color = '#595959' }: any) => (
  <svg
    width='24'
    style={{
      display: 'inline-block',
      cursor: 'pointer',
    }}
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.14286 20.625C5.14286 21.1223 5.32347 21.5992 5.64496 21.9508C5.96645 22.3025 6.40249 22.5 6.85714 22.5H17.1429C17.5975 22.5 18.0336 22.3025 18.355 21.9508C18.6765 21.5992 18.8571 21.1223 18.8571 20.625V7.5H5.14286V20.625ZM14.8571 10.625C14.8571 10.4592 14.9173 10.3003 15.0245 10.1831C15.1317 10.0659 15.277 10 15.4286 10C15.5801 10 15.7255 10.0659 15.8326 10.1831C15.9398 10.3003 16 10.4592 16 10.625V19.375C16 19.5408 15.9398 19.6997 15.8326 19.8169C15.7255 19.9342 15.5801 20 15.4286 20C15.277 20 15.1317 19.9342 15.0245 19.8169C14.9173 19.6997 14.8571 19.5408 14.8571 19.375V10.625ZM11.4286 10.625C11.4286 10.4592 11.4888 10.3003 11.5959 10.1831C11.7031 10.0659 11.8484 10 12 10C12.1516 10 12.2969 10.0659 12.4041 10.1831C12.5112 10.3003 12.5714 10.4592 12.5714 10.625V19.375C12.5714 19.5408 12.5112 19.6997 12.4041 19.8169C12.2969 19.9342 12.1516 20 12 20C11.8484 20 11.7031 19.9342 11.5959 19.8169C11.4888 19.6997 11.4286 19.5408 11.4286 19.375V10.625ZM8 10.625C8 10.4592 8.0602 10.3003 8.16737 10.1831C8.27453 10.0659 8.41988 10 8.57143 10C8.72298 10 8.86833 10.0659 8.97549 10.1831C9.08265 10.3003 9.14286 10.4592 9.14286 10.625V19.375C9.14286 19.5408 9.08265 19.6997 8.97549 19.8169C8.86833 19.9342 8.72298 20 8.57143 20C8.41988 20 8.27453 19.9342 8.16737 19.8169C8.0602 19.6997 8 19.5408 8 19.375V10.625ZM19.4286 3.75001H15.1429L14.8071 3.01954C14.736 2.86337 14.6265 2.73201 14.4908 2.64022C14.3552 2.54844 14.1988 2.49988 14.0393 2.50001H9.95714C9.79799 2.49934 9.64189 2.54772 9.50672 2.6396C9.37154 2.73149 9.26277 2.86316 9.19286 3.01954L8.85714 3.75001H4.57143C4.41988 3.75001 4.27453 3.81585 4.16737 3.93306C4.0602 4.05027 4 4.20925 4 4.37501V5.62501C4 5.79077 4.0602 5.94974 4.16737 6.06695C4.27453 6.18416 4.41988 6.25001 4.57143 6.25001H19.4286C19.5801 6.25001 19.7255 6.18416 19.8326 6.06695C19.9398 5.94974 20 5.79077 20 5.62501V4.37501C20 4.20925 19.9398 4.05027 19.8326 3.93306C19.7255 3.81585 19.5801 3.75001 19.4286 3.75001Z'
      fill={color}
    />
  </svg>
);
