export const StoreCreditIcon = ({ width = '24', height = '25' }) => (
  <svg
    width={width}
    style={{ display: 'inline-block' }}
    height={height}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.16 8.39961C20.07 7.93961 19.66 7.59961 19.18 7.59961H4.82C4.34 7.59961 3.93 7.93961 3.84 8.39961L3 12.5996V13.5996C3 14.1496 3.45 14.5996 4 14.5996V19.5996C4 20.1496 4.45 20.5996 5 20.5996H13C13.55 20.5996 14 20.1496 14 19.5996V14.5996H18V19.5996C18 20.1496 18.45 20.5996 19 20.5996C19.55 20.5996 20 20.1496 20 19.5996V14.5996C20.55 14.5996 21 14.1496 21 13.5996V12.5996L20.16 8.39961ZM12 18.5996H6V14.5996H12V18.5996ZM5 6.59961H19C19.55 6.59961 20 6.14961 20 5.59961C20 5.04961 19.55 4.59961 19 4.59961H5C4.45 4.59961 4 5.04961 4 5.59961C4 6.14961 4.45 6.59961 5 6.59961Z'
      fill='#595959'
    />
  </svg>
);
