import PharmacyImage from 'components/PharmacyImage';
import Notification from 'components/Header/Notification';
import Link from 'next/link';

export const MobileHeader = () => {
  return (
    <span className='lg:hidden grid grid-cols-3'>
      <span></span>
      <div className='flex items-center justify-center mb-4 mt-4'>
        <Link href={'/'} prefetch={false}>
          <PharmacyImage
            className='cursor-pointer'
            width={40}
            minHeight={40}
            height={40}
            objectFit='contain'
            src='/local/android-chrome-512x512.png'
            localImage
            alt='AR'
          />
        </Link>
      </div>
      <span className='text-right rtl:text-left px-3 pt-6'>
        <Notification />
      </span>
    </span>
  );
};
