export const CloseIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM14.9552 7.07469C15.4992 6.53066 16.3813 6.53066 16.9253 7.07469C17.4693 7.61873 17.4693 8.50078 16.9253 9.04482L13.9701 12L16.9253 14.9552C17.4693 15.4992 17.4693 16.3813 16.9253 16.9253C16.3813 17.4693 15.4992 17.4693 14.9552 16.9253L12 13.9701L9.04482 16.9253C8.50078 17.4693 7.61873 17.4693 7.07469 16.9253C6.53066 16.3813 6.53066 15.4992 7.07469 14.9552L10.0299 12L7.07469 9.04482C6.53066 8.50078 6.53066 7.61873 7.07469 7.07469C7.61873 6.53066 8.50078 6.53066 9.04482 7.07469L12 10.0299L14.9552 7.07469Z'
        fill='#757575'
      />
    </svg>
  );
};
