import { SVG } from 'lib/interface';
export const AddToWishlistRoundedSelectedIcon = ({ width = '30', height = '30' }: SVG) => {
  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='0.251953' width='24' height='24' rx='12' fill='white' fillOpacity='0.2' />
      <rect
        x='0.401153'
        y='0.653106'
        width='23.1977'
        height='23.1977'
        rx='11.5988'
        stroke='#ECECEC'
        stroke-opacity='0.5'
        strokeWidth='0.802305'
      />
      <g transform='scale(0.5) translate(12, 12)'>
        <path
          d='M12 18C12 19 12.25 19.92 12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C19.83 12.23 18.94 12 18 12C14.69 12 12 14.69 12 18ZM21.54 15.88L20.12 14.47L18 16.59L15.88 14.47L14.47 15.88L16.59 18L14.47 20.12L15.88 21.54L18 19.41L20.12 21.54L21.54 20.12L19.41 18L21.54 15.88Z'
          fill='#E81103'
        />
      </g>
    </svg>
  );
};
