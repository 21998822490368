import { SVG } from 'lib/interface';

export const AlarmIcon = ({ color = '#595959' }: SVG) => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.705 4.30961L4.295 2.88961C1 6.16261 1 8.53461 1 11.5996H2L3 11.5366C3 8.60861 3 6.99561 5.705 4.30961ZM19.704 2.88961L18.296 4.30961C21 6.99561 21 8.60861 21 11.5996L23 11.5366C23 8.53461 23 6.16261 19.704 2.88961ZM12 22.5996C12.6193 22.6004 13.2235 22.4082 13.7285 22.0498C14.2335 21.6913 14.6143 21.1845 14.818 20.5996H9.182C9.38566 21.1845 9.76648 21.6913 10.2715 22.0498C10.7765 22.4082 11.3807 22.6004 12 22.5996ZM19 15.1856V10.5996C19 7.38261 16.815 4.67261 13.855 3.85761C13.562 3.11961 12.846 2.59961 12 2.59961C11.154 2.59961 10.438 3.11961 10.145 3.85761C7.184 4.67261 5 7.38261 5 10.5996V15.1856L3.293 16.8926C3.19996 16.9853 3.12617 17.0955 3.07588 17.2168C3.0256 17.3382 2.99981 17.4683 3 17.5996V18.5996C3 18.8648 3.10536 19.1192 3.29289 19.3067C3.48043 19.4943 3.73478 19.5996 4 19.5996H20C20.2652 19.5996 20.5196 19.4943 20.7071 19.3067C20.8946 19.1192 21 18.8648 21 18.5996V17.5996C21.0002 17.4683 20.9744 17.3382 20.9241 17.2168C20.8738 17.0955 20.8 16.9853 20.707 16.8926L19 15.1856Z'
      fill={color}
    />
  </svg>
);
