import { toast } from 'react-toastify';

export const errorToast = (msg: string) => {
  toast.error(msg);
};

export const successToast = (msg: string) => {
  toast.success(msg);
};

export const warningToast = (msg: string) => {
  toast.warning(msg);
};
