import useCookieStorage from 'hooks/useCookieStorage';
import { useQuery } from '@tanstack/react-query';
import { wishlist } from 'models/wishlist';
import useStoreView from 'hooks/useStoreView';

export default function useWishlistQuery() {
  const [customerId] = useCookieStorage('isCustomerLoggedIn', false, {});
  const [storeView] = useStoreView();
  return useQuery(
    ['wishlist', storeView, customerId],
    async () => {
      const res = await wishlist();
      return res.items;
    },
    {
      enabled: !!customerId,
      staleTime: 1000 * 60 * 10,
    },
  );
}
