export const productUrlGenerator = (productName: string, sku: string) => {
  const formattedProductName = productName
    ?.replace(/[^\w\s]/gi, '')
    .replaceAll(' P ', '_p_')
    .replaceAll('/', '_')
    .replaceAll(' ', '-')
    .replace(/\\/g, '_')
    .toLowerCase();
  return `/product/${formattedProductName?.slice(0, 25)}-pid-${sku}`;
};

export const productUrlGeneratorWithUrlKey = (productName: string) => {
  return `/product/${productName}`;
};
