export const ShareInMapIcon = (props: { color?: string }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.29457 12.7072L11.2726 21.7072C11.6616 22.0972 12.2976 22.0982 12.6866 21.7092L21.7076 12.7092C21.8007 12.6163 21.8746 12.5059 21.925 12.3844C21.9754 12.263 22.0014 12.1327 22.0014 12.0012C22.0014 11.8696 21.9754 11.7394 21.925 11.6179C21.8746 11.4964 21.8007 11.3861 21.7076 11.2932L12.6866 2.29318C12.5936 2.20036 12.4833 2.12678 12.362 2.07664C12.2406 2.0265 12.1105 2.00079 11.9792 2.00098C11.8478 2.00116 11.7178 2.02724 11.5966 2.07773C11.4753 2.12821 11.3652 2.2021 11.2726 2.29518L2.29457 11.2952C2.2018 11.3879 2.12821 11.4979 2.078 11.6191C2.0278 11.7402 2.00195 11.87 2.00195 12.0012C2.00195 12.1323 2.0278 12.2622 2.078 12.3833C2.12821 12.5044 2.2018 12.6145 2.29457 12.7072ZM9.00157 10.0012H14.0016V8.00118L17.0016 11.0012L14.0016 14.0012V12.0012H11.0016V16.0012H9.00157V10.0012Z'
        fill={props.color ? props.color : '#35B050'}
      />
    </svg>
  );
};
