import { CategoriesIcon } from 'components/SvgIcons/CategoriesIcon';

function findNodeById(id, nodes) {
  for (const node of nodes) {
    if (node.entity_id === id) {
      return [node.name];
    }

    if (node.children_data) {
      const childPath = findNodeById(id, node.children_data);
      if (childPath) {
        return [node.name, ...childPath];
      }
    }
  }

  return null;
}

export function findCategoryById(id, nodes) {
  for (const node of nodes) {
    if (node.entity_id == id) {
      return node;
    }

    if (node.children_data) {
      const childPath = findCategoryById(id, node.children_data);
      if (childPath) {
        return childPath;
      }
    }
  }
  return null;
}

export const treeView = (id, data, isDesktop = false) => {
  const path = findNodeById(+id, data);

  if (!path) {
    return <></>;
  }

  return (
    <div className={isDesktop ? '' : 'px-4 pb-2'}>
      <span className=''>
        <CategoriesIcon />
      </span>
      <span className='px-2 text-[12px] text-secondary font-bold'>{'>'}</span>
      {path.map((nodeName, index) => (
        <span key={index} className='text-[12px] text-secondary font-bold'>
          {nodeName}
          {index < path.length - 1 && <span className='px-2'>{'>'}</span>}
        </span>
      ))}
    </div>
  );
};
