const MESSAGE_CHAR_LIMIT = 200;

export const turnErrorMessagesIntoKeyFormat = (message: string) => {
  return message
    .trim()
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '_')
    .substring(0, MESSAGE_CHAR_LIMIT)
    .toLocaleLowerCase();
};

export const turnErrorMessagesIntoLokaliseFormat = (message: string) => message.trim().replace(/%(\w+)/g, '{{$1}}');
