import { useEffect, useState } from 'react';

export const useDeviceDetector = () => {
  const [deviceInfo, setDeviceInfo] = useState<{ isMobile: boolean | null }>({ isMobile: null });

  useEffect(() => {
    const handleResize = () => {
      setDeviceInfo({
        isMobile: window.innerWidth <= 768,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return deviceInfo;
};
