import { SVG } from 'lib/interface';

export const BackIconTwo = ({ className }: SVG) => (
  <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.4138 12L17.8278 10.586C18.01 10.3974 18.1108 10.1448 18.1085 9.8826C18.1062 9.6204 18.0011 9.36959 17.8157 9.18418C17.6303 8.99877 17.3794 8.8936 17.1173 8.89133C16.8551 8.88905 16.6025 8.98984 16.4138 9.172L14.9998 10.586L13.5858 9.172C13.4936 9.07649 13.3833 9.00031 13.2613 8.9479C13.1392 8.89549 13.008 8.8679 12.8752 8.86675C12.7425 8.8656 12.6108 8.8909 12.4879 8.94118C12.365 8.99146 12.2533 9.06571 12.1595 9.15961C12.0656 9.2535 11.9913 9.36515 11.941 9.48805C11.8907 9.61094 11.8654 9.74262 11.8666 9.8754C11.8678 10.0082 11.8953 10.1394 11.9477 10.2614C12.0002 10.3834 12.0763 10.4938 12.1718 10.586L13.5858 12L12.1718 13.414C12.0763 13.5062 12.0002 13.6166 11.9477 13.7386C11.8953 13.8606 11.8678 13.9918 11.8666 14.1246C11.8654 14.2574 11.8907 14.3891 11.941 14.512C11.9913 14.6349 12.0656 14.7465 12.1595 14.8404C12.2533 14.9343 12.365 15.0085 12.4879 15.0588C12.6108 15.1091 12.7425 15.1344 12.8752 15.1333C13.008 15.1321 13.1392 15.1045 13.2613 15.0521C13.3833 14.9997 13.4936 14.9235 13.5858 14.828L14.9998 13.414L16.4138 14.828C16.5061 14.9235 16.6164 14.9997 16.7384 15.0521C16.8604 15.1045 16.9917 15.1321 17.1244 15.1333C17.2572 15.1344 17.3889 15.1091 17.5118 15.0588C17.6347 15.0085 17.7464 14.9343 17.8402 14.8404C17.9341 14.7465 18.0084 14.6349 18.0587 14.512C18.109 14.3891 18.1343 14.2574 18.1331 14.1246C18.1319 13.9918 18.1044 13.8606 18.052 13.7386C17.9995 13.6166 17.9234 13.5062 17.8278 13.414L16.4138 12ZM9.82785 5H19.9998C20.5303 5 21.039 5.21071 21.4141 5.58579C21.7891 5.96086 21.9998 6.46957 21.9998 7V17C21.9998 17.5304 21.7891 18.0391 21.4141 18.4142C21.039 18.7893 20.5303 19 19.9998 19H9.82785C9.29746 18.9999 8.78883 18.7891 8.41385 18.414L2.70685 12.707C2.51938 12.5195 2.41406 12.2652 2.41406 12C2.41406 11.7348 2.51938 11.4805 2.70685 11.293L8.41385 5.586C8.78883 5.2109 9.29746 5.00011 9.82785 5V5Z'
      fill='#595959'
    />
  </svg>
);
