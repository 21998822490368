import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { event } from 'components/GoogleTagManager';
import { OrderIcon } from 'components/SvgIcons/OrderIcon';
import { PrescriptionIcon } from 'components/SvgIcons/PrescriptionIcon';
import { HeartIcon } from 'components/SvgIcons/HeartIcon';
import { AlarmIcon } from 'components/SvgIcons/AlarmIcon';
import { RefundIcon } from 'components/SvgIcons/RefundIcon';
import { UserIcon } from 'components/SvgIcons/UserIcon';
import { Chevron } from 'components/SvgIcons/Chevron';
import { useLoading } from 'hooks/useLoading';

const SideBarMenu = ({ t }: { t: any }) => {
  const { push, pathname } = useRouter();
  const [, setLoading] = useLoading();
  const [menus, setMenus] = useState([
    {
      id: 1,
      name: 'my_orders',
      eventName: 'order',
      icon: OrderIcon,
      opened: false,
      selected: false,
      path: '/profile/orders',
    },
    {
      id: 2,
      name: 'prescriptions',
      eventName: 'prescription',
      icon: PrescriptionIcon,
      opened: false,
      selected: false,
      path: '/profile/prescriptions',
    },
    {
      id: 3,
      name: 'my_wishlist',
      eventName: 'my_whishlist',
      icon: HeartIcon,
      opened: false,
      selected: false,
      path: '/profile/wishlist',
    },
    {
      id: 4,
      name: 'alarm_list',
      eventName: 'alarm_list',
      icon: AlarmIcon,
      opened: false,
      selected: false,
      path: '/profile/alarms',
    },
    {
      id: 5,
      name: 'refunds_and_returns',
      eventName: 'refund_and_return',
      icon: RefundIcon,
      opened: false,
      selected: false,
      path: '/profile/refunds',
    },
    {
      id: 6,
      name: 'settings',
      eventName: 'profile_setting',
      icon: UserIcon,
      opened: true,
      selected: true,
      subMenu: [
        {
          id: 7,
          name: 'addresses',
          selected: true,
          path: '/profile/profile-settings/addresses',
        },
        {
          id: 8,
          name: 'payment_methods',
          selected: false,
          path: '/profile/profile-settings/payments',
        },
        {
          id: 9,
          name: 'account_information',
          selected: false,
          path: '/profile/profile-settings/account-information',
        },
        {
          id: 10,
          name: 'application_settings',
          selected: false,
          path: '/profile/profile-settings/account-settings',
        },
        {
          id: 11,
          name: 'allergies_and_conditions',
          selected: false,
          path: '/profile/profile-settings/allergies-and-conditions',
        },
      ],
    },
  ]);
  const [path, setPathname] = useState('');

  const handleMenuChange = (id: number, path: string | undefined) => {
    setMenus([
      ...menus.map((menu) => {
        if (menu.id !== id) return { ...menu, opened: false, selected: false };
        else return { ...menu, opened: !menu.opened, selected: true };
      }),
    ]);
    if (path) {
      push(path);
    }
  };

  useEffect(() => {
    setPathname(pathname);
    setMenus(
      menus.map((item) => {
        if (item.path === pathname || item.subMenu?.find((item) => item.path === pathname)) {
          return { ...item, opened: true, selected: true };
        }
        return { ...item, opened: false, selected: false };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <>
      {menus.map((menu) => (
        <div key={menu.id}>
          <div
            onClick={() => {
              pathname !== menu.path && menu.id !== 6 && setLoading(true);
              if (menu.path === pathname) return;

              handleMenuChange(menu.id, menu.path);
              event('profile_navigation', { tab_selected: menu.eventName });
            }}
            key={menu.id}
            className='grid grid-cols-4 pb-3 pt-3 border-b-[0.5px] border-grey cursor-pointer'
          >
            <div className='col-span-3 inline-flex text-[17px] font-[600] text-gray-900'>
              <menu.icon color={menu.selected ? '#2E8F66' : '#595959'} />{' '}
              <span className={`mx-2 ${menu.selected ? 'text-apollo-secondary' : ''}`}>{t(menu.name)}</span>
            </div>
            <div className='col-span-1 text-right rtl:text-left text-[16px] text-apollo-secondary font-bold'>
              <Chevron color={menu.selected ? '#2E8F66' : '#595959'} />
            </div>
          </div>
          {menu.opened &&
            menu.subMenu?.map((subMenu) => (
              <div
                onClick={() => {
                  pathname !== subMenu.path && setLoading(true);
                  if (subMenu.path === pathname) return;

                  push(subMenu.path);
                  setPathname(subMenu.path);
                }}
                key={subMenu.id}
                className={`grid grid-cols-1 pb-3 pt-3 border-b-[0.5px] border-grey cursor-pointer ${
                  subMenu.path === path ? 'text-apollo-secondary' : ''
                }`}
              >
                {t(subMenu.name)}
              </div>
            ))}
        </div>
      ))}
    </>
  );
};

export default SideBarMenu;
