import Badge from 'components/BadgePlpCustom';

const CustomBadges = ({ OfferTags = [], t }: { OfferTags: any; t: any }) => {
  function calculateStyles(item: any) {
    const { position } = item || {
      position: '',
    };

    switch (position) {
      case 'bottom':
      case 'below_image':
        return {
          bottom: '0',
          left: 'auto',
          right: 'auto',
          transform: 'none',
        };
      case 'top':
        return {
          bottom: 'auto',
          left: 'auto',
          right: 'auto',
          transform: 'none',
        };
      case 'bottom_left':
      case 'top_left':
        return {
          top: position === 'top_left' ? '0' : 'auto',
          bottom: position === 'bottom_left' ? '0px' : 'auto',
          left: '0',
          right: 'auto',
        };
      case 'bottom_right':
      case 'top_right':
        return {
          top: position === 'top_right' ? '0' : 'auto',
          bottom: position === 'bottom_right' ? '0' : 'auto',
          left: 'auto',
          right: '0',
        };
      case 'center':
        return {
          marginTop: '30%',
        };
      case 'center_left':
      case 'center_right':
        return {
          bottom: 'auto',
          top: 'auto',
          left: position === 'center_left' ? '0' : 'auto',
          right: position === 'center_right' ? '0' : 'auto',
          marginTop: '30%',
        };
      default:
        return {};
    }
  }
  const sortedOfferTags = OfferTags.slice().sort((a, b) => a?.priority - b?.priority);

  const positionsMap = {};

  const styles = calculateStyles(sortedOfferTags[0]);

  const pagdesArray = sortedOfferTags.map((item, i) => {
    if (positionsMap[item?.position] === undefined) {
      positionsMap[item?.position] = [i];
    } else {
      positionsMap[item?.position].push(i);
    }

    return <Badge t={t} key={i} item={item} />;
  });
  return (
    <div style={styles} className='flex gap-1 max-w-[156px] overflow-clip absolute flex-row h-fit w-fit'>
      {pagdesArray}
    </div>
  );
};

export default CustomBadges;
