import jwt, { JwtPayload } from 'jsonwebtoken';

export const checkIfBase64TokenValid = (base64Token?: string) => {
  if (!base64Token) {
    return false;
  }

  const decodedString = Buffer.from(base64Token, 'base64').toString('utf8');

  const validUntilPos = decodedString.indexOf('validUntil=');
  if (validUntilPos === -1) {
    return false;
  }

  const expirationTimeStr = decodedString.substring(validUntilPos + 'validUntil='.length);
  const expirationTime = parseInt(expirationTimeStr, 10);

  if (isNaN(expirationTime)) {
    return false;
  }

  const currentTime = Date.now() / 1000;
  const buffer = 5;

  return expirationTime > currentTime + buffer;
};

export const checkIfTokenValid = (token?: string) => {
  if (!token) {
    return false;
  }
  const decodedToken = jwt.decode(token) as JwtPayload;
  const expirationTime = decodedToken?.exp ?? 0;
  const currentTime = Date.now() / 1000;
  const buffer = 5;
  return expirationTime > currentTime + buffer;
};
