export const initializeAdjust = (setAdjust?: React.Dispatch<React.SetStateAction<any>>) => {
  if (typeof window !== 'undefined') {
    console.log('Adjust initialized');

    const Adjust = require('@adjustcom/adjust-web-sdk');
    Adjust.initSdk({
      appToken: '7tfdwbuv9z40',
      environment: 'production',
      logLevel: 'info',
    });

    return Adjust;
  }
};
