import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { event } from 'components/GoogleTagManager';
import { NextSeo } from 'next-seo';
import { useLoading } from 'hooks/useLoading';
import PharmacyImage from 'components/PharmacyImage';
import BackIcon from 'public/image/back-icon.svg';
import { DeviceDetector } from 'utils/dd';

const Header = ({
  t,
  title = 'Dr. Sulaiman Al Habib Pharmacy  | صيدلية د. سليمان الحبيب',
  description = 'A pharmacy website',
  openGraph,
  metaTags,
}: {
  t: any;
  title?: string;
  description?: string;
  openGraph?: any;
  metaTags?: any;
}) => {
  const { push, locale, asPath } = useRouter();
  const [, setLoading] = useLoading();
  const isMobile = useContext(DeviceDetector).isMobile;

  useEffect(() => {
    setLoading(false);
  }, [locale]);

  return (
    <div>
      <NextSeo
        title={title}
        defaultTitle='Dr. Sulaiman Al Habib Pharmacy  | صيدلية د. سليمان الحبيب'
        description={description}
        openGraph={openGraph}
        additionalMetaTags={metaTags}
      />
      <div className='full-width'>
        <div className='grid grid-cols-24 pt-2 pb-1'>
          <div className={`${isMobile ? 'col-start-1' : 'col-start-3'} col-span-12 text-[14px]`}>
            {isMobile && (
              <button onClick={() => push('/')} className='rtl:rotate-180 px-2'>
                <BackIcon />
              </button>
            )}
            <button
              onClick={() => {
                push(asPath, asPath, { locale: locale == 'ar-sa' ? 'en-sa' : 'ar-sa' }).finally(() => {
                  window.location.reload();
                });
                event('language_change', { language: locale !== 'ar-sa' ? 'ar' : 'en' });
              }}
              className='cursor-pointer cursor-pointer lg:flex items-start justify-start'
            >
              <PharmacyImage
                width={36}
                height={27}
                objectFit='fill'
                src={locale !== 'ar-sa' ? '/local/ar-n.png' : '/local/static/images/en.png'}
                localImage
                alt={locale !== 'ar-sa' ? 'AR' : 'EN'}
              />

              <span className='mx-1 align-[3px] hidden md:inline'>{locale !== 'ar-sa' ? 'عربي' : 'EN'}</span>
            </button>
          </div>
        </div>
        <div className='bg-apollo-white h-[106px] border-t-2 border-grey'>
          <div className='hero container max-w-screen-lg mt-5 mx-auto flex justify-center'>
            {/* <Link href={'/'} prefetch={false}> */}
            <span
              onClick={() => {
                push('/');
              }}
            >
              <PharmacyImage
                className='cursor-pointer'
                width={272}
                height={64}
                src='/local/static/images/logo@text.svg'
                localImage
                alt='AR'
              />
            </span>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
