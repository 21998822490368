import { useQuery } from '@tanstack/react-query';
import { notifications } from 'models/notifications';
import useCookieStorage from 'hooks/useCookieStorage';
import useStoreView from 'hooks/useStoreView';

export default function useNotifications() {
  const [customerId] = useCookieStorage('isCustomerLoggedIn', false, {});
  const [storeView] = useStoreView();
  return useQuery(
    ['notifications', storeView],
    async () => {
      return notifications();
    },
    {
      enabled: !!customerId,
      staleTime: 1000 * 60 * 5,
    },
  );
}
