import { parseCookies } from 'nookies';
import axios, { AxiosRequestConfig, GenericAbortSignal } from 'axios';
import { NextApiRequest, NextPageContext } from 'next/types';
import { parse } from 'next-useragent';
import { isWindowExists } from 'utils/is-window-exists';
import { getAccessToken } from 'utils/get-access-token';
import { checkIfTokenValid } from '../utils/check-expiration';

const axiosInstance = axios.create({});

const requestInterceptorUrlsToIgnore = ['/handshake', '/handshake/refresh'];
const baseURL = process.env.NEXT_PUBLIC_API_PRIVATE_URL || 'https://core-api.alhabibpharmacy.net';

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig & { nextPageContext?: NextPageContext }) => {
    if (requestInterceptorUrlsToIgnore.some((url) => config.url?.split('?')[0] === `${baseURL}${url}`)) {
      return config;
    }

    const ctx = config?.nextPageContext;

    if (!checkIfTokenValid(parseCookies({ req: ctx?.req }).accessToken)) {
      const { accessToken } = await getAccessToken(ctx);
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest.isSentbefore) {
      originalRequest.isSentbefore = true;

      originalRequest.headers = {
        ...originalRequest.headers,
        Authorization: `Bearer ${(await getAccessToken(originalRequest?.nextPageContext, error.response.data.message)).accessToken}`,
      };

      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  },
);

const generateDefaultHeaders = (
  req?: NextApiRequest & { accessToken?: string; userIpAddress: string; storeView: string },
) => {
  const isInClientSide = isWindowExists();
  const parsedUserAgent = isInClientSide && parse(window?.navigator?.userAgent);
  const accessToken = isInClientSide ? parseCookies()?.accessToken : req?.cookies?.accessToken;

  const userIp = isInClientSide ? parseCookies()?.userIpAddress : req?.userIpAddress;

  const storeview =
    (isInClientSide ? JSON.parse(window.localStorage.getItem('storeView')!) : req?.storeView) || 'alhabib_en';

  return {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'X-Frame-Options': 'SAMEORIGIN',
    'x-device-os-type': 'web',
    'x-app-type': 'web',
    // 'Content-Security-Policy': "script-src-attr 'self';",
    ...(parsedUserAgent && {
      'x-app-device': parsedUserAgent.browser,
      'x-app-version': parsedUserAgent.browserVersion,
    }),
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(userIp && { 'x-apollo-forwarded-for': userIp }),
    'x-store-view': storeview,
    'X-Content-Type-Options': 'nosniff',
  };
};

const createRequestUrl = (url: string, req: any, customURL?: boolean) => {
  if (customURL) return url;
  if (
    typeof window === 'undefined' &&
    process.env.NEXT_PUBLIC_API_PRIVATE_URL == 'https://core-api.alhabibpharmacy.net'
  ) {
    return `http://prod-core.prod:3000${url}`;
  }
  return `${baseURL}${url}`;
};

// DF-1805: Added flag to bypass store code when loading CMS for brand page. As the storeView is not used anymore
export const post = async (url: string, body?: any, req?: any, customURL?: boolean) => {
  const isInClientSide = isWindowExists();
  const storeview =
    (isInClientSide ? JSON.parse(window.localStorage.getItem('storeView')!) : req?.storeView) || 'alhabib_en';
  url = url?.includes('?') ? url + `&store_view=${storeview}` : url + `?store_view=${storeview}`;
  return axiosInstance({
    method: 'post',
    url: createRequestUrl(url, req, customURL),
    data: body,
    headers: generateDefaultHeaders(req),
    nextPageContext: req?.ctx as NextPageContext,
  } as AxiosRequestConfig);
};

export const get = async (url: string, req?: any, signal?: GenericAbortSignal) => {
  const isInClientSide = isWindowExists();
  const storeview =
    (isInClientSide ? JSON.parse(window.localStorage.getItem('storeView')!) : req?.storeView) || 'alhabib_en';
  url = url?.includes('?') ? url + `&store_view=${storeview}` : url + `?store_view=${storeview}`;

  return axiosInstance({
    method: 'get',
    url: createRequestUrl(url, req),
    headers: generateDefaultHeaders(req),
    signal: signal,
    nextPageContext: req?.ctx as NextPageContext,
  } as AxiosRequestConfig);
};

export const put = async (url: string, body?: any, req?: any) => {
  const isInClientSide = isWindowExists();
  const storeview =
    (isInClientSide ? JSON.parse(window.localStorage.getItem('storeView')!) : req?.storeView) || 'alhabib_en';
  url = url?.includes('?') ? url + `&store_view=${storeview}` : url + `?store_view=${storeview}`;
  const fullUrl = createRequestUrl(url, req);

  return axiosInstance({
    method: 'put',
    url: fullUrl,
    data: body,
    headers: generateDefaultHeaders(req),
    nextPageContext: req?.ctx as NextPageContext,
  } as AxiosRequestConfig);
};

export const del = async (url: string, body?: any, req?: any) => {
  const isInClientSide = isWindowExists();
  const storeview =
    (isInClientSide ? JSON.parse(window.localStorage.getItem('storeView')!) : req?.storeView) || 'alhabib_en';
  url = url?.includes('?') ? url + `&store_view=${storeview}` : url + `?store_view=${storeview}`;

  const obj = {
    method: 'delete',
    url: createRequestUrl(url, req),
    headers: generateDefaultHeaders(req),
    data: body,
    nextPageContext: req?.ctx as NextPageContext,
  } as AxiosRequestConfig;
  return axiosInstance(obj);
};
