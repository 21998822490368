export const CartIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.36398 0.605793C3.89448 0.998372 4.28017 1.54955 4.46275 2.176L4.99534 4H21.9607C22.294 4.00021 22.6222 4.08042 22.9165 4.23364C23.2109 4.38685 23.4625 4.6084 23.6493 4.87892C23.8361 5.14943 23.9525 5.46068 23.9882 5.78546C24.024 6.11024 23.978 6.43865 23.8544 6.742L20.8466 14.114C20.6194 14.6708 20.2271 15.1481 19.7204 15.4843C19.2137 15.8205 18.6159 16.0001 18.004 16H8.68062C8.01581 16 7.36907 15.7878 6.83819 15.3956C6.30731 15.0034 5.92115 14.4524 5.73811 13.826L2.50175 2.726C2.44105 2.51717 2.31264 2.33338 2.13593 2.2024C1.96544 2.07603 1.75893 2.00545 1.54563 2.0003L1.52227 2H1.02029C0.749692 2 0.490177 1.89464 0.298836 1.70711C0.107495 1.51957 0 1.26522 0 1C0 0.734784 0.107495 0.48043 0.298836 0.292893C0.490177 0.105357 0.749692 0 1.02029 0H1.52227L1.52566 2.87544e-06C1.54636 4.3052e-05 1.56705 0.000290713 1.58771 0.000741233C2.22934 0.0147316 2.8509 0.226108 3.36398 0.605793ZM5.90571 7.29827L7.78071 13.2983C7.91117 13.7158 8.29781 14 8.73519 14H17.846C18.2433 14 18.6029 13.7648 18.7621 13.4008L21.3871 7.40082C21.6762 6.74013 21.1921 6 20.471 6H6.86019C6.18555 6 5.70448 6.65434 5.90571 7.29827Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.18196 22C9.32679 22 9.46929 21.972 9.60099 21.9185C9.73262 21.8651 9.84964 21.7878 9.94638 21.693C10.043 21.5982 10.1175 21.488 10.1676 21.3695C10.2176 21.2511 10.2428 21.1257 10.2428 21C10.2428 20.8743 10.2176 20.7489 10.1676 20.6305C10.1175 20.512 10.043 20.4018 9.94638 20.307C9.84964 20.2122 9.73262 20.1349 9.60099 20.0815C9.46929 20.028 9.32679 20 9.18196 20C8.88778 20 8.61355 20.1149 8.41754 20.307C8.2229 20.4978 8.12109 20.7479 8.12109 21C8.12109 21.2521 8.2229 21.5022 8.41754 21.693C8.61355 21.8851 8.88778 22 9.18196 22ZM10.3533 23.7716C9.98194 23.9224 9.58392 24 9.18196 24C8.37017 24 7.59163 23.6839 7.0176 23.1213C6.44358 22.5587 6.12109 21.7956 6.12109 21C6.12109 20.2044 6.44358 19.4413 7.0176 18.8787C7.59163 18.3161 8.37017 18 9.18196 18C9.58392 18 9.98194 18.0776 10.3533 18.2284C10.7247 18.3791 11.0621 18.6001 11.3463 18.8787C11.6306 19.1573 11.856 19.488 12.0098 19.8519C12.1637 20.2159 12.2428 20.606 12.2428 21C12.2428 21.394 12.1637 21.7841 12.0098 22.1481C11.856 22.512 11.6306 22.8427 11.3463 23.1213C11.0621 23.3999 10.7247 23.6209 10.3533 23.7716Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.346 22C17.4909 22 17.6334 21.972 17.765 21.9185C17.8967 21.8651 18.0137 21.7878 18.1104 21.693C18.2071 21.5982 18.2816 21.488 18.3317 21.3695C18.3817 21.2511 18.4069 21.1257 18.4069 21C18.4069 20.8743 18.3817 20.7489 18.3317 20.6305C18.2816 20.512 18.2071 20.4018 18.1104 20.307C18.0137 20.2122 17.8967 20.1349 17.765 20.0815C17.6334 20.028 17.4909 20 17.346 20C17.0518 20 16.7776 20.1149 16.5816 20.307C16.387 20.4978 16.2852 20.7479 16.2852 21C16.2852 21.2521 16.387 21.5022 16.5816 21.693C16.7776 21.8851 17.0518 22 17.346 22ZM18.5174 23.7716C18.146 23.9224 17.748 24 17.346 24C16.5342 24 15.7557 23.6839 15.1817 23.1213C14.6076 22.5587 14.2852 21.7956 14.2852 21C14.2852 20.2044 14.6076 19.4413 15.1817 18.8787C15.7557 18.3161 16.5342 18 17.346 18C17.748 18 18.146 18.0776 18.5174 18.2284C18.8887 18.3791 19.2262 18.6001 19.5104 18.8787C19.7946 19.1573 20.0201 19.488 20.1739 19.8519C20.3277 20.2159 20.4069 20.606 20.4069 21C20.4069 21.394 20.3277 21.7841 20.1739 22.1481C20.0201 22.512 19.7946 22.8427 19.5104 23.1213C19.2262 23.3999 18.8887 23.6209 18.5174 23.7716Z'
        fill='white'
      />
    </svg>
  );
};
